import {
  DELETE_EXPENSE_SUMMARY_REQUEST, DELETE_EXPENSE_SUMMARY_SUCCESS, DELETE_EXPENSE_SUMMARY_FAILURE,
  GET_EXPENSE_SUMMARIES_REQUEST, GET_EXPENSE_SUMMARIES_SUCCESS, GET_EXPENSE_SUMMARIES_FAILURE,
  POST_EXPENSE_SUMMARY_REQUEST, POST_EXPENSE_SUMMARY_SUCCESS, POST_EXPENSE_SUMMARY_FAILURE,
  UPDATE_EXPENSE_SUMMARY_REQUEST, UPDATE_EXPENSE_SUMMARY_SUCCESS, UPDATE_EXPENSE_SUMMARY_FAILURE,
  GET_EXPENSE_SUMMARIES_BY_AUTH_REQUEST, GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS, GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE,
  GET_EXPENSE_LINES_BY_AUTH_FAILURE, GET_EXPENSE_LINES_BY_AUTH_REQUEST, GET_EXPENSE_LINES_BY_AUTH_SUCCESS,
  GET_EXPENSE_LINES_FAILURE, GET_EXPENSE_LINES_REQUEST, GET_EXPENSE_LINES_SUCCESS,
  GET_EXPENSE_LINE_FAILURE, GET_EXPENSE_LINE_REQUEST, GET_EXPENSE_LINE_SUCCESS,
  UPDATE_EXPENSE_LINE_FAILURE, UPDATE_EXPENSE_LINE_REQUEST, UPDATE_EXPENSE_LINE_SUCCESS,
  POST_EXPENSE_LINE_FAILURE, POST_EXPENSE_LINE_REQUEST, POST_EXPENSE_LINE_SUCCESS,
  DELETE_EXPENSE_LINE_FAILURE, DELETE_EXPENSE_LINE_REQUEST, DELETE_EXPENSE_LINE_SUCCESS,
  GET_EXPENSE_LINE_BY_SUMMARY_ID_REQUEST, GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS, GET_EXPENSE_LINE_BY_SUMMARY_ID_FAILURE,
  POST_EXPENSE_LINE_MULTIPLE_REQUEST, POST_EXPENSE_LINE_MULTIPLE_SUCCESS, POST_EXPENSE_LINE_MULTIPLE_FAILURE,
  GET_EXPENSE_LINE_CUSTOM_TAXES_REQUEST,
  GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  GET_EXPENSE_LINE_CUSTOM_TAXES_FAILURE,
  POST_EXPENSE_LINE_CUSTOM_TAXES_REQUEST,
  POST_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  POST_EXPENSE_LINE_CUSTOM_TAXES_FAILURE,
  UPDATE_EXPENSE_LINE_CUSTOM_TAXES_REQUEST,
  UPDATE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  UPDATE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE,
  DELETE_EXPENSE_LINE_CUSTOM_TAXES_REQUEST,
  DELETE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  DELETE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE,
  GET_VOUCHER_SUMMARIES_REQUEST, GET_VOUCHER_SUMMARIES_SUCCESS, GET_VOUCHER_SUMMARIES_FAILURE,
  GET_VOUCHER_SUMMARIES_BY_AUTH_REQUEST, GET_VOUCHER_SUMMARIES_BY_AUTH_SUCCESS, GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE,
  POST_VOUCHER_SUMMARY_REQUEST, POST_VOUCHER_SUMMARY_SUCCESS, POST_VOUCHER_SUMMARY_FAILURE,
  UPDATE_VOUCHER_SUMMARY_REQUEST, UPDATE_VOUCHER_SUMMARY_SUCCESS, UPDATE_VOUCHER_SUMMARY_FAILURE,
  DELETE_VOUCHER_SUMMARY_REQUEST, DELETE_VOUCHER_SUMMARY_SUCCESS, DELETE_VOUCHER_SUMMARY_FAILURE,
  GET_EXPENSE_LINE_BY_LINE_ID_FAILURE, GET_EXPENSE_LINE_BY_LINE_ID_REQUEST, GET_EXPENSE_LINE_BY_LINE_ID_SUCCESS,
  GET_CLIENT_PREFERRED_EXPENSE_CODES_REQUEST,
  GET_CLIENT_PREFERRED_EXPENSE_CODES_SUCCESS,
  GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE,
} from './types';
import { CALL_INTAKE_V2_API } from 'middleware/intakeV2Api';

export const getExpenseSummaries = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EXPENSE_SUMMARIES_REQUEST, GET_EXPENSE_SUMMARIES_SUCCESS, GET_EXPENSE_SUMMARIES_FAILURE],
      authenticated: true,
      endpoint: 'api/expenses',
      method: 'GET',
    },
  };
};

export const getExpenseSummariesByAuth = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EXPENSE_SUMMARIES_BY_AUTH_REQUEST, GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS, GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/${id}`,
      method: 'GET',
    },
  };
};

export const postExpenseSummary = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_EXPENSE_SUMMARY_REQUEST, POST_EXPENSE_SUMMARY_SUCCESS, POST_EXPENSE_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: 'api/expenses',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateExpenseSummary = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_EXPENSE_SUMMARY_REQUEST, UPDATE_EXPENSE_SUMMARY_SUCCESS, UPDATE_EXPENSE_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: 'api/expenses',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteExpenseSummary = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_EXPENSE_SUMMARY_REQUEST, DELETE_EXPENSE_SUMMARY_SUCCESS, DELETE_EXPENSE_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/${id}`,
      method: 'DELETE',
    },
  };
};

export const getExpenseLines = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EXPENSE_LINES_REQUEST, GET_EXPENSE_LINES_SUCCESS, GET_EXPENSE_LINES_FAILURE],
      authenticated: true,
      endpoint: 'api/expenses/ExpenseLine',
      method: 'GET',
    },
  };
};

export const getExpenseLinesByAuthId = (authorizationId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EXPENSE_LINES_BY_AUTH_REQUEST, GET_EXPENSE_LINES_BY_AUTH_SUCCESS, GET_EXPENSE_LINES_BY_AUTH_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/ExpenseLine/${authorizationId}`,
      method: 'GET',
    },
  };
};

export const getExpenseLinesById = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EXPENSE_LINE_REQUEST, GET_EXPENSE_LINE_SUCCESS, GET_EXPENSE_LINE_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/ExpenseLine/${id}`,
      method: 'GET',
    },
  };
};

export const getExpenseLinesByLineId = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EXPENSE_LINE_BY_LINE_ID_REQUEST, GET_EXPENSE_LINE_BY_LINE_ID_SUCCESS, GET_EXPENSE_LINE_BY_LINE_ID_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/ExpenseLine/GetById/${id}`,
      method: 'GET',
    },
  };
};

export const getExpenseLinesBySummaryId = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EXPENSE_LINE_BY_SUMMARY_ID_REQUEST, GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS, GET_EXPENSE_LINE_BY_SUMMARY_ID_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/ExpenseLine/GetBySummaryId/${id}`,
      method: 'GET',
    },
  };
};

export const postExpenseLine = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_EXPENSE_LINE_REQUEST, POST_EXPENSE_LINE_SUCCESS, POST_EXPENSE_LINE_FAILURE],
      authenticated: true,
      endpoint: 'api/expenses/ExpenseLine',
      method: 'POST',
      payload: values,
    },
  };
};

export const postMultipleExpenseLines = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_EXPENSE_LINE_MULTIPLE_REQUEST, POST_EXPENSE_LINE_MULTIPLE_SUCCESS, POST_EXPENSE_LINE_MULTIPLE_FAILURE],
      authenticated: true,
      endpoint: 'api/expenses/ExpenseLineMultiple',
      method: 'PUT',
      payload: values,
    },
  };
}

export const updateExpenseLine = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_EXPENSE_LINE_REQUEST, UPDATE_EXPENSE_LINE_SUCCESS, UPDATE_EXPENSE_LINE_FAILURE],
      authenticated: true,
      endpoint: 'api/expenses/ExpenseLine',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteExpenseLine = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_EXPENSE_LINE_REQUEST, DELETE_EXPENSE_LINE_SUCCESS, DELETE_EXPENSE_LINE_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/ExpenseLine/${id}`,
      method: 'DELETE',
    },
  };
};

export const getExpenseLineCustomTaxes = (expenseId, expenseLineId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EXPENSE_LINE_CUSTOM_TAXES_REQUEST, GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS, GET_EXPENSE_LINE_CUSTOM_TAXES_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/${expenseId}/expenseLines/${expenseLineId}/customTaxes`,
      method: 'GET',
    },
  };
};

export const postExpenseLineCustomTax = (expenseId, expenseLineId, values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_EXPENSE_LINE_CUSTOM_TAXES_REQUEST, POST_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS, POST_EXPENSE_LINE_CUSTOM_TAXES_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/${expenseId}/expenseLines/${expenseLineId}/customTaxes`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateExpenseLineCustomTax = (expenseId, expenseLineId, customTaxId, values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_EXPENSE_LINE_CUSTOM_TAXES_REQUEST, UPDATE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS, UPDATE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/${expenseId}/expenseLines/${expenseLineId}/customTaxes/${customTaxId}`,
      method: 'PUT',
      payload: values,
    },
  };
}


export const deleteExpenseLineCustomTax = (expenseId, expenseLineId, customTaxId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_EXPENSE_LINE_CUSTOM_TAXES_REQUEST, DELETE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS, DELETE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE],
      authenticated: true,
      endpoint: `api/expenses/${expenseId}/expenseLines/${expenseLineId}/customTaxes/${customTaxId}`,
      method: 'DELETE',
    },
  };
};

/* Voucher Requests */

export const getVoucherSummaries = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_VOUCHER_SUMMARIES_REQUEST, GET_VOUCHER_SUMMARIES_SUCCESS, GET_VOUCHER_SUMMARIES_FAILURE],
      authenticated: true,
      endpoint: 'api/vouchers',
      method: 'GET',
    },
  };
};

export const getVoucherSummariesByAuth = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_VOUCHER_SUMMARIES_BY_AUTH_REQUEST, GET_VOUCHER_SUMMARIES_BY_AUTH_SUCCESS, GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE],
      authenticated: true,
      endpoint: `api/vouchers/${id}`,
      method: 'GET',
    },
  };
};

export const postVoucherSummary = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_VOUCHER_SUMMARY_REQUEST, POST_VOUCHER_SUMMARY_SUCCESS, POST_VOUCHER_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: 'api/vouchers',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateVoucherSummary = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_VOUCHER_SUMMARY_REQUEST, UPDATE_VOUCHER_SUMMARY_SUCCESS, UPDATE_VOUCHER_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: 'api/vouchers',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteVoucherSummary = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_VOUCHER_SUMMARY_REQUEST, DELETE_VOUCHER_SUMMARY_SUCCESS, DELETE_VOUCHER_SUMMARY_FAILURE],
      authenticated: true,
      endpoint: `api/vouchers/${id}`,
      method: 'DELETE',
    },
  };
};

export const getAllPreferredExpenseCodes= () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_CLIENT_PREFERRED_EXPENSE_CODES_REQUEST, GET_CLIENT_PREFERRED_EXPENSE_CODES_SUCCESS, GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE],
      authenticated: true,
      endpoint: `api/ExpenseCodes/clientPreferred`,
      method: 'GET',
    },
  };
};

/* eslint-disable array-callback-return */
import {
    Alert,
    Box,
    Card,
    CardContent,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { showToast } from 'modules/layout/layout.actions';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => (
    {
        card: {
            width: '100%',
            flexGrow: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        cardContainer: {
            maxWidth: '100% !important',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',

        },
        iconRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        dialogIconRow: {
            display: 'flex',
            flexDirection: 'row',
            padding: '40px',
            paddingBottom: '20px',
        },
        cardDisplayContent: {
            maxWidth: '100% !important',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            margin: '10px',
        },
        chip: {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        chipClickable: {
            cursor: 'pointer',
        },
        iconOverlayImg: {
            position: 'absolute',
        },
        spacingX: {
            marginRight: theme.spacing(1),
        },
        footer: {
            height: 15,
            marginBottom: theme.spacing(2),
        },
        mb2: {
            marginBottom: '6.5px',
            marginTop: '6.5px',
            marginRight: '16px',
        },
        editModalContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100% !important',
            marginLeft: '0px !important',
        },
        editModalColumn: {
            display: 'flex',
            flexDirection: 'column',
            width: '100% !important',
        },
        editModalInput: {
            minWidth: '332px',
        },
        dialogContentContainer: {
            margin: '15px',
        },
        labelText: {
            minWidth: 350,
            marginBottom: 0,
        },
        initiatedStatusBox: {
            border: '1px solid #67a783',
            borderRadius: '5px',
            padding: '5px',
            textAlign: 'center',
            minHeight: 30,
            background: '#67a783',
        },
        notInitiatedStatusBox: {
            border: '1px solid #fd6120',
            borderRadius: '5px',
            padding: '5px',
            textAlign: 'center',
            minHeight: 30,
            background: '#fd6120',
        },
        expensesStatusBox: {
            border: '1px solid #868686',
            borderRadius: '5px',
            padding: '5px',
            textAlign: 'center',
            minHeight: 30,
            background: '#868686',
        },
    }
));

/*
      - Required Props: 
       * tabs - a list of tabs to be included in the card
       * summaries - a list of objects detailing the specific textfield requirements for the editing feature as well as the necessary values to display
       * numColumns - number of xl device columns
      - Optional Props: 
       * updateFields (typically an API PUT call followed by GET call to refresh the data, returns boolean) 
  
      - Required Label Fields: accessorKey, header, type, options (only required for dropdown type)
      - Optional Label Fields: required (defaults to false), size, enableColumnOrdering, enableEditing, enableSorting, hideInTable (if column should be displayed in the CRUD table or not)
  
      - Label Types: text, notes, number, date, dropdown, dropdownv2
  
      Tabs are displayed in the order they appear in the list. If there is only one item in the list, then it will be displayed as a 'header' (not clickable, no other visible tabs) and all items in the summaries list will be displayed and editable by default. If within the summaries list
      there is an item with a 'tab' option of a different value than the 'order' in the single item within the tabs list. (Example - there is an item in the summaries list with a 'tab' value of 3. There is only one item in the tabs list. That item will still be displayed within the card.)
  
      const tabs = [
          { accessorKey: 'nameOfTabMatchingInSummaries', header: 'displayTitleOfTab', order: 1, iconPath: ['fas', 'user-friends'] },
      ];
  
      The editable card is largely based off of the CRUD table functionality. An example of a label input is:
      const summaries = [
          { header: 'Text Example', type: 'text', required: true, size: 72 },
          { header: 'Dropdown Example', type: 'dropdown', options: ['Yes', 'No'], required: true, size: 68 },
          { header: 'Dropdown Version 2 Example', type: 'dropdown-v2', options: arrayOfOptions, defaultVal: 0, required: true, Cell: ({cell}) => cell.fieldValues.original.dropdownV2Example != null && <p>{dropdownV2Exampoe.find(o => o.id === cell.fieldValues.dropdownV2Example).title}</p>} ,
          { header: 'Checkbox Example', type: 'checkbox', defaultVal: false, required: true, Cell: ({cell}) => <Checkbox name="checkboxExample" label="Active" checked=(cell.fieldValues.original.checkboxExample} disabled={false} },
          { header: 'Date Example', type: 'date', required: true, size: 72 },
          { header: 'Notes Example', type: 'notes', required: true},
      ]
  
      Included in the summaries array, you need a VALUE that contains the proper value you want displayed. An example is that fields = authorizationInformation. Values will then be gathered by the accessor key in the summaries array:
  
            We want to display the values for an authorization's ID and Client:
            const summaries = [
              { header: 'File #', value: this.props.authorizationInformation.mtAuthorizationId, type: 'text', required: true, disabled: true },
              { header: 'Client', value: this.props.authorizationInformation.client.description, type: 'text', required: true, disabled: true },
            ]
  
      The updateFields props is a FUNCTION for updating the fields via the editing modal. Here is an example of how to write an updateFields function:
      
      const updateAuthorizationInformation = async (authorizationInformation, values) => {
        const id = authorizationInformation.mtAuthorizationId;
        values.mtAuthorizationId = id;
        values.clientId = authorizationInformation.clients.clientId;
        const resp = await props.updateAuthorizationInformation(values);
        if (resp.type === UPDATE_AUTHORIZATION_INFORMATION_FAILURE) {
          return false;
        } else {
        const getResp = await props.getAuthorizationInformation(authorizationInformation.clients.clientId);
        if (getResp.type === GET_AUTHORIZATION_INFORMATION_FAILURE) {
          return false;
        } else {
          return true;
        }
      }
    };
  
  */


const NotesAndCommunicationEditableCard = (props) => {
    const { servicesInfo, } = props;
    const classes = useStyles();

    const theme = createTheme({
        palette: {
            primary: COLOR_PRIMARY,
            secondary: COLOR_SECONDARY,
            warning: COLOR_WARNING,
        },

    });

    return (
        <ThemeProvider theme={theme}>
            <Card className={classes.card}>
                <Box>
                    <CardContent className={classes.cardContainer}>
                        <div className="row justify-space-between align-center">
                            <div className={classes.iconRow}>
                                <Tooltip placement="right">
                                    <FontAwesomeIcon icon={['fas', 'comment']} size="2x" />
                                </Tooltip>
                                <Typography
                                variant="h5"
                                sx={{
                                    fontSize: '1.3125rem',
                                    fontWeight: 'bold',
                                    marginLeft: '10px',
                                }} >
                                    Pinned Note
                                </Typography>
                            </div>
                        </div>
                    </CardContent>
                    <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }}>
                            <Grid item md="12" >
                                <Box>
                                    <div className={classes.mb2}>
                                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                            Example pinned comment.
                                        </Typography>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item md="3" >
                                <div className={classes.mb2}>
                                    <Typography variant="subtitle2" sx={{ 'a': { color: '#36939B' } }}>
                                        <Link>Notes</Link>
                                    </Typography>
                                </div>
                            </Grid>
                            <Box sx={{ width: '100%' }}>
                                <hr />
                            </Box>
                            <Grid item md="2" >
                                <div className={classes.mb2}>
                                    <Typography variant="subtitle2" sx={{ 'a': { color: '#36939B' } }}>
                                        <Link to={`/authorizations/${servicesInfo.authorizationId}/expenses`}>Expenses</Link>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item md="4">
                                <Alert
                                    severity="info"
                                    iconMapping={{
                                        info: <InfoIcon fontSize="small" color="white !important" sx={{ marginRight: '-5px', color: 'white !important' }} />,
                                    }}
                                    sx={{
                                        fontSize: '0.875rem',
                                        maxHeight: 35,
                                        padding: '5px',
                                        marginRight: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'black',
                                        color: 'white !important',
                                    }}
                                >
                                    <Typography variant="body2">
                                        {servicesInfo && servicesInfo.expenses}
                                    </Typography>
                                </Alert>
                            </Grid>
                            <Grid item md="2" >
                                <div className={classes.mb2}>
                                    <Typography variant="subtitle2" sx={{ 'a': { color: '#36939B' } }}>
                                        <Link to={`/authorizations/${servicesInfo.authorizationId}/exceptions`}>Exceptions</Link>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item md="3">
                                <Typography variant="body2">
                                    1
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            </Card>

        </ThemeProvider>
    );
};
NotesAndCommunicationEditableCard.propTypes = {
    showToast: PropTypes.func.isRequired,
};
export default connect(
    null,
    { showToast },
)(NotesAndCommunicationEditableCard);

import {
  GET_EXPENSE_SUMMARIES_REQUEST,
  GET_EXPENSE_SUMMARIES_SUCCESS,
  GET_EXPENSE_LINES_REQUEST,
  GET_EXPENSE_LINES_SUCCESS,
  GET_VOUCHER_SUMMARIES_REQUEST,
  GET_VOUCHER_SUMMARIES_SUCCESS,
  POST_EXPENSE_LINE_SUCCESS,
  UPDATE_EXPENSE_LINE_SUCCESS,
  GET_CLIENT_PREFERRED_EXPENSE_CODES_SUCCESS
} from './types';
import produce from 'immer';

const initialState = {
  expenseSummaries: null,
  voucherSummaries: null,
  expenseLines: null,
  isLoadingExpenseSummaries: false,
  isLoadingVoucherSummaries: false,
  isLoadingExpenseLines: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_EXPENSE_SUMMARIES_REQUEST:
      draft.isLoadingExpenseSummaries = true;
      break;

    case GET_EXPENSE_SUMMARIES_SUCCESS:
      draft.isLoadingExpenseSummaries = false;
      draft.expenseSummaries = action.response;
      break;

    case GET_VOUCHER_SUMMARIES_REQUEST:
      draft.isLoadingExpenseSummaries = true;
      break;

    case GET_VOUCHER_SUMMARIES_SUCCESS:
      draft.isLoadingVoucherSummaries = false;
      draft.voucherSummaries = action.response;
      break;

    case GET_EXPENSE_LINES_REQUEST:
      draft.isLoadingExpenseLines = true;
      break;

    case GET_EXPENSE_LINES_SUCCESS:
      draft.isLoadingExpenseLines = false;
      draft.expenseLines = action.response;
      break;

    case POST_EXPENSE_LINE_SUCCESS:
      if (!Array.isArray(draft.expenseLines)) {
        draft.expenseLines = [];
      }

      draft.expenseLines = [...draft.expenseLines, action.response];
      break;

    case UPDATE_EXPENSE_LINE_SUCCESS: {
      const expenseLineItems = draft.expenseLines;
      if (expenseLineItems && Array.isArray(expenseLineItems)) {
        const indexToUpdate = expenseLineItems.findIndex(s => s.expenseLineId === action.response?.expenseLineId);
        if (indexToUpdate !== -1) {
          expenseLineItems[indexToUpdate] = action.response;
          draft.expenseLines = [...expenseLineItems];
        }
      }
      break;
    }

    case GET_CLIENT_PREFERRED_EXPENSE_CODES_SUCCESS:
      draft.clientPreferredExpenseCodes = action.response;
      break;

    default:
      break;
  }

  return draft;
}, initialState);

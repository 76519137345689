/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TableRow,
  TextField
} from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState
} from '@devexpress/dx-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Spinner from './spinner.component';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: '65vh',
    maxHeight: '65vh'
  }
}));

/**
 * Searchable Dropdown uses the 'Autocomplete' react component to create a searchable dropdown. Once the user starts typing, it will return the top 20 closest results out of all results.
 *
 * Input Props:
 * serachingCriteria (required): The columns to be displayed in the modal table
 * searchingCriteriaExtensions (required): The column types for sorting
 * searchingRows (required): The data to populate the table, called by a GET function
 * labelText: The label of the search textbox
 * displayValueAccessorKey: The 'accessorKey' of the value that should be SAVED within the textfield. This accessorKey MUST be one of the columns in the table.
 * saveIdValueAccessorKey: The 'accessorKey' of the value that should be SAVED. This value will be passed to the OnChange function.
 * onChange (function): The onChange function for the field to save. NEEDS to take in a parameter that contains the value to be SAVED.
 *
 */

const AdvancedSearch = props => {
  const {
    searchingCriteria,
    searchingCriteriaExtensions,
    searchingRows,
    labelText,
    displayValueAccessorKey,
    saveIdValueAccessorKey,
    onChange,
    value,
    id,
    setIdValue,
    maxWidthsx,
    isReadOnly
  } = props;

  const classes = useStyles();
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const [searchingModalOpen, setSearchingModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const iconAdornment = value
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon size="1x" icon={['fas', 'fa-magnifying-glass']} color="gray" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Button
              sx={{ padding: 0, margin: 0, maxWidth: '15px', minWidth: '15px' }}
              onClick={() => {
                if (value !== '') {
                  setIdValue([], '');
                  setSelected([]);
                  setSearchingModalOpen(false);
                } else {
                  setSearchingModalOpen(false);
                }
              }}
            >
              <FontAwesomeIcon size="1x" icon={['fas', 'fa-xmark']} color="gray" />
            </Button>
          </InputAdornment>
        ),
        readOnly: true
      }
    : {
        startAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon size="1x" icon={['fas', 'fa-magnifying-glass']} color="gray" />
          </InputAdornment>
        ),
        readOnly: true
      };

  const saveSelected = selectedValue => {
    if (selectedValue) {
      setSelected(selectedValue);
      const arrayVal = searchingRows[selectedValue];
      if (arrayVal) {
        setIdValue(arrayVal[saveIdValueAccessorKey], arrayVal[displayValueAccessorKey]);
      }
      // the ID of the row is set to the value to save for onChange
      setSearchingModalOpen(false); //required to exit the searching modal and close it
    } else {
      setSelected(selectedValue);
      let selectId = null;
      let selectVal = '';
      setIdValue(selectId, selectVal);
      setSearchingModalOpen(false); //required to exit the searching modal and close it
    }
  };

  const closeModal = () => {
    setSearchingModalOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <TextField
        label={labelText}
        id={id}
        sx={{ input: { cursor: !isReadOnly ? 'pointer' : 'default' }, ...(maxWidthsx && { maxWidth: maxWidthsx }) }}
        onClick={() => setSearchingModalOpen(true)}
        onChange={onChange}
        value={value}
        placeholder="Click to search..."
        InputProps={!isReadOnly ? iconAdornment : null}
        disabled={isReadOnly}
      />
      {searchingModalOpen && (
        <AdvancedSearchModal
          classes={classes}
          columns={searchingCriteria}
          rows={searchingRows}
          columnExtensions={searchingCriteriaExtensions}
          open={searchingModalOpen}
          label={labelText}
          val={selected}
          onClose={closeModal}
          onSave={saveSelected}
        />
      )}
    </ThemeProvider>
  );
};
AdvancedSearch.propTypes = {
  searchingCriteria: PropTypes.object.isRequired,
  searchingCriteriaExtensions: PropTypes.object.isRequired,
  searchingRows: PropTypes.object.isRequired,
  labelText: PropTypes.string.isRequired,
  displayValueAccessorKey: PropTypes.string.isRequired,
  saveIdValueAccessorKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showToast: PropTypes.func.isRequired
};
export default connect(null, {
  showToast
})(AdvancedSearch);

const customFilterCell = props => {
  if (props.column?.type === 'dropdown') {
    return (
      <TableFilterRow.Cell {...props}>
        <Select
          value={props.filter ? props.filter.value : ''}
          onChange={event => props.onFilter(event.target.value ? { value: event.target.value, operation: 'contains' } : null)}
          displayEmpty
          fullWidth
          variant="standard"
          sx={{
            fontSize: '0.75rem'
          }}
        >
          <MenuItem value="" sx={{ fontSize: '0.75rem' }}>
            All
          </MenuItem>
          {props.column.options.map(option => (
            <MenuItem key={option} value={option} sx={{ fontSize: '0.75rem' }}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </TableFilterRow.Cell>
    );
  }

  return <TableFilterRow.Cell {...props} />;
};

export const AdvancedSearchModal = ({ classes, columns, columnExtensions, rows, open, onClose, onSave, label, val }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 15, 20]);
  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [selection, setSelection] = useState(val ? val : []);

  const handleClose = e => {
    setIsLoading(false);
    e.preventDefault();
    onClose();
  };

  const handleSave = e => {
    if (selection[0] || selection[0] === 0) {
      setIsLoading(false);
      //put your validation logic here
      e.preventDefault();
      onSave(selection);
    } else {
      setIsLoading(false);
      e.preventDefault();
      onSave(null);
    }
  };

  const renderHeaderRow = row => {
    return (
      <TableRow sx={{ backgroundColor: '#41B7C0', '&.SortLabel-sortLabelRoot-160': { color: 'white !important' } }}>
        {row.children.map(c => {
          return c;
        })}
      </TableRow>
    );
  };

  return (
    <Dialog open={open} maxWidth={'lg'} classes={{ paper: classes.dialogPaper }}>
      {!isLoading && (
        <form onSubmit={handleSave} fullScreen>
          <Box sx={{ padding: 3, width: '100%' }}>
            <DialogTitle textAlign="center" sx={{ padding: '0 0 20px 0' }}>
              Search for {label}
            </DialogTitle>
            <Paper>
              <Grid rows={rows} columns={columns}>
                <SortingState sorting={sorting} onSortingChange={setSorting} />
                <IntegratedSorting />
                <SelectionState
                  selection={selection}
                  onSelectionChange={e => {
                    let v = [];
                    v.push(e[e.length - 1]);
                    setSelection(v);
                  }}
                />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <FilteringState filters={filters} onFiltersChange={setFilters} />
                <IntegratedSelection />
                <IntegratedFiltering />
                <IntegratedPaging />
                <Table columnExtensions={columnExtensions} />
                <TableHeaderRow rowComponent={renderHeaderRow} showSortingControls />
                <TableFilterRow cellComponent={customFilterCell} />
                <PagingPanel pageSizes={pageSizes} />
                <TableSelection showSelectAll={false} highlightRow selectByRowClick />
              </Grid>
            </Paper>
          </Box>
        </form>
      )}
      {isLoading && <Spinner />}
      {!isLoading && (
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary" type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

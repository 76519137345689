/* eslint-disable react/prop-types */
import { Divider, Grid, Typography, Tooltip, Box, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Field } from 'formik';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AutocompleteTextField, Checkbox, ChipSelect, TextField, ToggleSelect } from 'modules/intake/form';
import { IS_RENTED_OPTIONS, metadataSort, YES_NO_OPTIONS } from 'utilities/common';
import { showToast } from 'modules/layout/layout.actions';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import ExpansionPanel from 'modules/common/expansionPanel.component';
import ExpansionPanelSummary from 'modules/common/expansionPanelSummary.component';
import Log from 'utilities/log';

import { HomesaleProcessBenefits, isCandidateMoveType, isInternMoveType } from 'modules/intake/models';
import PropertyCreditBalanceForm from './propertyCreditBalanceForm.component';
import PropertyEscrowForm from './propertyEscrowForm.component';
import PropertyFloodInsuranceForm from './propertyFloodInsuranceForm.component';
import PropertyHoaForm from './propertyHoaForm.component';
import PropertyLeaseBreakForm from './propertyLeaseBreakForm.component';
import PropertyTitleLegalNameForm from './propertyTitleLegalNameForm.component';
import styles from 'modules/intake/styles';
import WarningIcon from '@material-ui/icons/Warning';
import Hint from 'modules/common/hint.component';
import PropertyPricingForm from './propertyPricingForm.component';
import PropertyInspectionForm from './propertyInspectionForm.component';
import PropertyNotesForm from './propertyNotesForm.component';
import PropertyFeaturesForm from './propertyFeaturesForm.component';

const renderDivider = (title) => (
  <Grid item xs={12}>
    <div className="my-2">
      <Divider variant="fullWidth" className="full-width" />
    </div>
    <Typography variant="h4">{title}</Typography>
  </Grid>
);


class DepartureProperty extends Component {
  state = {
    nextOwnershipEvent: null,
    nextSellingEvent: null,
    showConfirmation: false,
  };


  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.departureProperty, nextProps.departureProperty) ||
      !isEqual(this.props.eeIntakeForm, nextProps.eeIntakeForm) || 
      this.props.isSubmitting !== nextProps.isSubmitting ||
      !isEqual(this.state, nextState) ||
      !isEqual(this.props.moveTypeId, nextProps.moveTypeId)
    );
  }

  renderHeader() {
    const { moveTypeId, classes, departureProperty, eeIntakeForm, handleChange, handleBlur, metadata, departureAddressComment } = this.props;
    const isCandidateOrIntern = (isCandidateMoveType(moveTypeId, metadata.moveTypes) || isInternMoveType(moveTypeId, metadata.moveTypes)) ? true:false;
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <Typography gutterBottom variant="h3">
              Departure Property
            </Typography>
          </Grid>
          {departureAddressComment ? (
            <Grid item>
              <Tooltip title={<Typography variant="body1">Transferee has left a comment regarding Departure Property</Typography>}>
                <Box
                  sx={{
                    height: '30px',
                    width: '30px',
                    backgroundColor: '#f0b11d',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 1
                  }}
                >
                  <WarningIcon sx={{ color: 'white' }} />
                </Box>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
        {isCandidateOrIntern && (
          <>
            <Field
              component={Checkbox}
              className={classes.headerToggle}
              name="isInitiated"
              onChange={event => {
                // this value is the current state, not what it will change to if we allow it
                if (event.target.value !== 'checked') {
                  this.setState({ showChangeMoveType: true });
                }
              }}
              onBlur={handleBlur}
              label="Initiate Service"
            />
          </>
        )}
        {!isCandidateOrIntern && (
          <>
            <div className="row mb-2">
              <div>
                <Field
                  component={ToggleSelect}
                  name="departureProperty.isRental"
                  value={departureProperty.isRental}
                  onChange={event => {
                    // show message if it was previously set to true or false
                    if (departureProperty.isRental !== null) {
                      this.setState({ nextOwnershipEvent: event });
                    } else {
                      handleChange(event);
                    }
                  }}
                  onBlur={handleBlur}
                  options={IS_RENTED_OPTIONS}
                  label="Do you currently own or rent your home?"
                />
                {eeIntakeForm?.record.isSubmitted && eeIntakeForm?.record.isRental != null && (
                  <Typography style={{ color: 'green' }}>^ Sourced from EE</Typography>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  renderHomeTypeOption() {
    const { departureProperty, eeIntakeForm, handleChange, handleBlur } = this.props;

    return (
      
      <div className="row mb-2">
        <div>
          <Field
            component={ToggleSelect}
            name="departureProperty.isRental"
            value={departureProperty.isRental}
            onChange={(event) => {
              // show message if it was previously set to true or false
              if (departureProperty.isRental !== null) {
                this.setState({ nextOwnershipEvent: event });
              } else {
                handleChange(event);
              }
            }}
            onBlur={handleBlur}
            options={IS_RENTED_OPTIONS}
            label="Do you currently own or rent your home?"
          />     
          {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.isRental != null) && (
            <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
          )}
        </div>
      </div>
        
    );
  }

  renderRentedProperty() {
    const { departureProperty, eeIntakeForm, handleChange, handleBlur, metadata } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Lease Break</Typography>
        </Grid>
        <Grid item md={12}>
          <PropertyLeaseBreakForm
            metadata={metadata}
            leaseBreak={departureProperty.leaseBreak}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.leaseBreak"
            eeIntakeForm={eeIntakeForm}
          />
        </Grid>
      </Grid>
    );
  }

  setHomesaleType = (isEligible, isSelling) => {
    const { metadata, setFieldValue } = this.props;
    console.log(metadata);
    if (isSelling && !isEligible) {
      const hmaHomeSaleType = values(metadata.homesaleTypes).find((instance) => String(instance.description).toUpperCase() === 'HMA');
      if (hmaHomeSaleType) {
        setFieldValue('departureProperty.homesaleTypeId', hmaHomeSaleType.id, false);
      }
    }
  };

  renderOwnedProperty() {
    const { departureProperty, eeIntakeForm, handleChange, handleBlur, metadata } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>   
          <div className="row">
            <Field
              component={ToggleSelect}
              name="departureProperty.isEligibleForHomesale"
              value={departureProperty.isEligibleForHomesale}
              onChange={(e) => {
                this.setHomesaleType(!departureProperty.isEligibleForHomesale, departureProperty.isSellingDepartureProperty);
                handleChange(e);
              }}
              onBlur={handleBlur}
              options={YES_NO_OPTIONS}
              label="Is the Transferee eligible for Home Sale services?"
              alignCenter
            />
            <Field
              component={ToggleSelect}
              name="departureProperty.homesaleTypeId"
              value={departureProperty.homesaleTypeId}
              onChange={handleChange}
              onBlur={handleBlur}
              options={values(metadata.homesaleTypes)}
              variant="spread"
              disabled={!departureProperty.isEligibleForHomesale}
              label="What Home Sale program type is the Transferee eligible for?"
              labelPlacement="top"
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div>
            <Field
              component={ToggleSelect}
              name="departureProperty.isSellingDepartureProperty"
              value={departureProperty.isSellingDepartureProperty}
              onChange={(event) => {
                // show message if it was previously set to true or false
                if (departureProperty.isSellingDepartureProperty !== null) {
                  this.setState({ nextSellingEvent: event });
                } else {
                  this.setHomesaleType(departureProperty.isEligibleForHomesale, !departureProperty.isSellingDepartureProperty);
                  handleChange(event);
                }
              }}
              onBlur={handleBlur}
              options={YES_NO_OPTIONS}
              label="Are you planning to sell your home?"
            />
            {eeIntakeForm?.record.isSubmitted && (eeIntakeForm?.record.isSellingDepartureProperty != null) && (
              <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
            )}
          </div>
        </Grid>

        {departureProperty.isSellingDepartureProperty && this.renderDeparturePropertyDetails()}
      </Grid>
    );
  }


  renderDeparturePropertyDetails() {
    const { departureProperty, handleChange, handleBlur, submitForm, metadata, isSubmitting } = this.props;

    return (
      <Grid container spacing={2}>
        {renderDivider('Property Overview')}
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Field
              component={ToggleSelect}
              name={`departureProperty.exteriorDetails.housePropertyTypeId`}
              value={departureProperty.exteriorDetails.housePropertyTypeId}
              onChange={handleChange}
              onBlur={handleBlur}
              options={values(metadata.housePropertyTypes)}
              variant="spread"
              label="Property Type"
              labelPlacement="top"
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item>
            <Field
              component={TextField}
              name={`departureProperty.exteriorDetails.purchasedDate`}
              label="Date Purchased"
              value={departureProperty.exteriorDetails.purchasedDate}
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
            />
          </Grid>
          <Grid item>
            <Field
              component={TextField}
              name={`departureProperty.exteriorDetails.purchasePrice`}
              label="Purchase Price"
              value={departureProperty.exteriorDetails.purchasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              allowDecimal={true}
              numeric
              prefix="$"
            />
          </Grid>
          <Grid item xs={6}>
            <PropertyCreditBalanceForm
              metadata={metadata}
              balance={departureProperty.balance}
              handleChange={handleChange}
              handleBlur={handleBlur}
              rootPath="departureProperty.balance"
              isSubmitting={isSubmitting}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item>
            <PropertyTitleLegalNameForm
              metadata={metadata}
              titleLegalName={departureProperty.titleLegalName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              submitForm={submitForm}
              rootPath="departureProperty.titleLegalName"
              isSubmitting={isSubmitting}
            />
          </Grid>
          <Grid item>
            <PropertyEscrowForm
              metadata={metadata}
              escrow={departureProperty.escrow}
              handleChange={handleChange}
              handleBlur={handleBlur}
              rootPath="departureProperty.escrow"
            />
            <PropertyFloodInsuranceForm
              metadata={metadata}
              floodInsurance={departureProperty.floodInsurance}
              handleChange={handleChange}
              handleBlur={handleBlur}
              rootPath="departureProperty.floodInsurance"
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item>
            <PropertyHoaForm
              metadata={metadata}
              hoa={departureProperty.hoa}
              handleChange={handleChange}
              handleBlur={handleBlur}
              rootPath="departureProperty.hoa"
            />
          </Grid>
        </Grid>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item>
            <Field
              component={ToggleSelect}
              name={`departureProperty.newConstruction.isNewConstruction`}
              value={departureProperty.newConstruction.isNewConstruction}
              onChange={handleChange}
              onBlur={handleBlur}
              options={YES_NO_OPTIONS}
              label="New Construction"
            />
          </Grid>

          {departureProperty.newConstruction.isNewConstruction && (
            <Grid item>
              <Hint>Fully Assessed for Land and Dwelling?</Hint>
            </Grid>
          )}

          <Grid item>
            <Field
              component={TextField}
              name={`departureProperty.exteriorDetails.yearBuilt`}
              label="Year Built"
              value={departureProperty.exteriorDetails.yearBuilt}
              onChange={handleChange}
              onBlur={handleBlur}
              numeric
              inputProps={{
                maxLength: 4
              }}
              includeThousandsSeparator={false}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} alignItems="center" spacing={2}>
          <Grid item style={{ marginTop: '-8px' }}>
            <Field
              component={AutocompleteTextField}
              name={`departureProperty.exteriorDetails.overallHouseStyle`}
              label="Overall Style"
              value={departureProperty.exteriorDetails.overallHouseStyle}
              onChange={handleChange}
              onBlur={handleBlur}
              suggestions={values(metadata.houseStyleTypes)}
              showSuggestionOnFocus={true}
            />
          </Grid>
          <Grid item>
            <Field
              component={ChipSelect}
              name={`departureProperty.exteriorDetails.houseSidingTypes`}
              label="Siding Types"
              value={departureProperty.exteriorDetails.houseSidingTypes}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              labelWidth={82}
            >
              {values(metadata.houseSidingTypes)
                .sort(metadataSort)
                .map(type => (
                  <MenuItem key={type.id} value={type}>
                    {type.description}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          <Grid item>
            <Field
              component={TextField}
              name={`departureProperty.features.lotSize`}
              label="Lot Acreage"
              value={departureProperty.features.lotSize}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item>
            <Field
              component={TextField}
              name={`departureProperty.exteriorDetails.ageOfHouseRoof`}
              label="Age of Roof"
              value={departureProperty.exteriorDetails.ageOfHouseRoof}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item style={{ marginTop: '-12px' }}>
            <Field
              component={ToggleSelect}
              name={`departureProperty.exteriorDetails.hasRoofBeenReplaced`}
              label="Roof Replaced"
              value={departureProperty.exteriorDetails.hasRoofBeenReplaced}
              onChange={handleChange}
              onBlur={handleBlur}
              options={YES_NO_OPTIONS}
              alignCenter
            />
          </Grid>
        </Grid>
        {renderDivider('Interior Features')}
        <Grid item xs={12}>
          <PropertyFeaturesForm
            metadata={metadata}
            features={departureProperty.features}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.features"
          />
        </Grid>
        {renderDivider('Other Items')}
        <Grid item xs={12}>
          <PropertyNotesForm
            metadata={metadata}
            notes={departureProperty.notes}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.notes"
          />
        </Grid>
        <Grid item xs={12}>
          <PropertyInspectionForm
            metadata={metadata}
            inspection={departureProperty.inspection}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.inspection"
          />
        </Grid>
        <Grid item xs={12}>
          <PropertyPricingForm
            metadata={metadata}
            pricing={departureProperty.pricing}
            balance={departureProperty.balance}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.pricing"
          />
        </Grid>
      </Grid>
    );
  }
  handleOwnershipChange = () => {
    const { handleChange } = this.props;
    handleChange(this.state.nextOwnershipEvent);
    this.props.setFieldValue('benefits.homesaleProcess', new HomesaleProcessBenefits(), false);
    this.setState({ nextOwnershipEvent: null });
  };

  handleSellingChange = () => {
    const { departureProperty, handleChange } = this.props;
    const todaysDate = new Date().toISOString();
    let date = todaysDate.substring(0, 10);

    this.setHomesaleType(departureProperty.isEligibleForHomesale, !departureProperty.isSellingDepartureProperty);
    this.props.setFieldValue('benefits.homesaleProcess', new HomesaleProcessBenefits(), false);
    this.props.setFieldValue('benefits.homesaleProcess.sendBrokerRequestLetter', true);
    this.props.setFieldValue('benefits.homesaleProcess.sendAgentInfoDate', date);

    handleChange(this.state.nextSellingEvent);
    this.setState({ nextSellingEvent: null });
  };

  render() {
    Log.trace('RENDER', 'DepartureProperty');
    const { moveTypeId, departureProperty, metadata } = this.props;
    const { nextOwnershipEvent, nextSellingEvent, showChangeMoveType, showConfirmation } = this.state;
    const isCandidateOrIntern = (isCandidateMoveType(moveTypeId, metadata.moveTypes) || isInternMoveType(moveTypeId, metadata.movetypes)) ? true:false;
    return (
      <div className="form-group">
        {isCandidateOrIntern &&
          <>
            <ExpansionPanel >
              <ExpansionPanelSummary>
                {this.renderHeader()}
              </ExpansionPanelSummary>
            </ExpansionPanel>
          </>}
        {!isCandidateOrIntern &&
          <>
            {this.renderHeader()}
            {departureProperty.isRental && this.renderRentedProperty()}
            {departureProperty.isRental === false && this.renderOwnedProperty()}
          </>}
        
        
        <ConfirmationDialog
          open={!!nextOwnershipEvent}
          title="Change Ownership Type?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleOwnershipChange}
          onCancel={() => this.setState({ nextOwnershipEvent: null })}
        >
          <Typography>
            If you change the ownership type, you will lose all data entered in this section and any timeline entries related to homesale. <br /><br />Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
        <ConfirmationDialog
          open={!!nextSellingEvent}
          title="Change Decision to Sell?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleSellingChange}
          onCancel={() => this.setState({ nextSellingEvent: null })}
        >
          <Typography>
            If you change the decision to sell, you will lose all data entered in this section and any timeline entries related to homesale. <br /><br /> Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
        <ConfirmationDialog
          open={showConfirmation}
          title="Disable Service?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleDisableService}
          onCancel={() => this.setState({ showConfirmation: false })}
        >
          <Typography>
            If you disable this service, you will lose all data entered in this section and any timeline entries related to this service. <br /><br />Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
        <ConfirmationDialog
          open={showChangeMoveType}
          title="Change Move Type?"
          confirmLabel="Change Move Type"
          cancelLabel="Cancel"
          onConfirm={() => {window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); this.setState({ showChangeMoveType: false });}}
          onCancel={() => this.setState({ showChangeMoveType: false })}
        >
          <Typography>
            In order to initiate Destination Services, please change the move type from either Candidate or Intern.{' '}
            <br />
            <br />
            Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

DepartureProperty.propTypes = {
  classes: PropTypes.object.isRequired,
  departureProperty: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  connect(null, {
    showToast,
  }),
)(DepartureProperty);

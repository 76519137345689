import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Tooltip, MenuItem } from '@mui/material';
import { withTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { getAllVendorAuthNotesByAuthId, postVendorAuthNote } from 'modules/authorizations/store/actions/dataManagement.actions';
import { GET_VENDOR_NOTES_BY_AUTH_FAILURE, POST_VENDOR_NOTE_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { showToast } from 'modules/layout/layout.actions';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../layout/types';

const useStyles = makeStyles((theme) => ({
  wideControl: {
    minWidth: 174
  },
  section: {
    marginBottom: theme.spacing(2)
  },
  selfServicePolicySelect: {
    minWidth: 300
  },
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px',
    paddingBottom: '15px',
  },
  allNotesHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    paddingBottom: '10px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    marginLeft: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));
const NotesModal = props => {
  const {
    isNotesModalOpen,
    authorizationId,
    vendorNotes,
    handleCloseNotesModal,
  } = props;


  const [newNote, setNewNote] = useState('');
  const [newService, setNewService] = useState(null)
  const [newSection, setNewSection] = useState(null);
  const [vendorAuthNotes, setVendorAuthNotes] = useState([]);


  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const serviceOptions = [
    { title: 'Homesale', id: 1 },
    { title: 'Global', id: 2 },
    { title: 'HHG', id: 3 },
  ];

  const createNewNote = async (newService, newSection, newNote) => {

    let serviceName = serviceOptions.find((s) => s.id = newService);

    let values = {
      authorizationId: authorizationId,
      service: serviceName.title,
      section: newSection,
      note: newNote,
    }

    let response = await props.postVendorAuthNote(values);
    if (response.type === POST_VENDOR_NOTE_FAILURE) {
      props.showToast('Failed to create new note, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
    else {
      props.showToast('New Note created successfully.', { userMustDismiss: false, severity: 'success' });
      let getResponse = await props.getAllVendorAuthNotesByAuthId(authorizationId);
      if(getResponse.type !== GET_VENDOR_NOTES_BY_AUTH_FAILURE)
      {
        setVendorAuthNotes(getResponse.response);
      }
      setNewNote('');
      setNewSection(null);
      setNewService(null);
    }
  };

  const populateSectionOptions = (serviceId) => {
    let sectionOptions;
    switch (serviceId) {
      case 1:
        sectionOptions = ['Property Info', 'Appraisal Info', 'BMA Notes', 'Equity Details', 'Inspections'];
        break;
      case 2:
        sectionOptions = ['Assignment Details'];
        break;
      case 3:
        sectionOptions = ['Goods Shipment', 'Car Shipment', 'Long Term Storage'];
        break;
      default:
        sectionOptions = [''];
        break;
    }
    return sectionOptions;
  };


  const handleSubmit = () => {
    //put your validation logic here
    // need to add 'modified on / by' logic here
    createNewNote(newService, newSection, newNote);
  };


  useEffect(() => {
    const fetchNotes = async () => {
      const data = await props.getAllVendorAuthNotesByAuthId(authorizationId);;
      setVendorAuthNotes(data.response);
   }

   fetchNotes();
  }, [vendorNotes, authorizationId, props]);

  const renderNote = (note) => {
    return (
      <Box sx={{ marginLeft: '20px', marginTop: '10px', marginBottom: '10px' }}>
        <div className="row">
          <div sx={{ flexDirection: 'column' }}>
            <Box sx={{ typography: 'body1', color: '#000000', fontWeight: 'bold', fontSize: '1.15rem', marginRight: '20px', marginBottom: '10px' }}>
              Service
            </Box>
            <Box sx={{ typography: 'body2', color: '#43423a', fontWeight: 'regular', fontSize: '1rem', marginRight: '20px', marginBottom: '10px' }}>
              {note.service}
            </Box>
          </div>
          <div sx={{ flexDirection: 'column', marginLeft: '40px' }}>
            <Box sx={{ typography: 'body1', color: '#000000', fontWeight: 'bold', fontSize: '1.15rem', marginRight: '20px', marginBottom: '10px' }}>
              Section
            </Box>
            <Box sx={{ typography: 'body2', color: '#43423a', fontWeight: 'regular', fontSize: '1rem', marginRight: '20px', marginBottom: '10px' }}>
              {note.section}
            </Box>
          </div>
        </div>
        <div className="row">
          <div sx={{ flexDirection: 'column' }}>
            <Box sx={{ typography: 'body1', color: '#000000', fontWeight: 'bold', fontSize: '1.15rem', marginRight: '20px', marginBottom: '10px' }}>
              Note
            </Box>
            <Box sx={{ typography: 'body2', color: '#43423a', fontWeight: 'regular', fontSize: '1rem', marginRight: '20px', marginBottom: '20px' }}>
              {note.note}
            </Box>
          </div>
        </div>
        <Box sx={{ width: '100%', marginBottom: '10px' }}>
          <hr />
        </Box>
      </Box>

    );
  };

  //sx={{ mt: 2, flexGrow: 1 }}

  return (
    <ThemeProvider theme={theme}>
      <Modal open={isNotesModalOpen} onClose={handleCloseNotesModal} aria-labelledby="notes-modal-title">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            width: '80vw',
            //height: '80vh',
            minHeight: '50vh',
            maxWidth: '80vw',
            maxHeight: '80vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div className={classes.dialogIconRow}>
              <Tooltip title={'notes'} key={'New Notes'} placement="right">
                <FontAwesomeIcon icon={['fas', 'fa-clipboard']} size="2x" />
              </Tooltip>
              <Box sx={{ typography: 'h5', paddingLeft: '20px' }}>
              Add New Note
            </Box>
          </div>
          <div className={classes.dialogContentContainer}>
            <Box sx={{ padding: '5px', marginTop: '10px' }}>
              <TextField
                select
                label="Service"
                value={newService}
                onChange={(e) => setNewService(e.target.value)}
                SelectProps={{
                  displayEmpty: true,
                }}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                variant="outlined"
              >
                {serviceOptions &&
                  serviceOptions.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))
                }
              </TextField>
              <TextField
                select
                label="Section"
                value={newSection}
                onChange={(e) => setNewSection(e.target.value)}
                SelectProps={{
                  displayEmpty: true,
                }}
                sx={{ maxWidth: '360px', minWidth: '180px', marginLeft: '10px' }}
                variant="outlined"
              >
                {newService &&
                  populateSectionOptions(newService).map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box sx={{ padding: '10px' }}>
              <TextField
                label={"New Note"}
                value={newNote}
                margin="dense"
                multiline
                variant="standard"
                rows={4}
                type="text"
                sx={{ width: '100%' }}
                onChange={(e) => setNewNote(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 1
              }}
            >
              <Button
                color="secondary"
                variant="contained"
                sx={{ maxHeight: '40px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }}
                onClick={handleCloseNotesModal}
              >
                Close
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ maxHeight: '40px', marginLeft: '10px' }}
                onClick={() => handleSubmit()}
              >
                Save Note
              </Button>
            </Box>
            <Box sx={{ width: '100%', padding: '10px' }}>
              <hr />
            </Box>
          </div>
          <div className={classes.allNotesHeaderRow}>
            <Box sx={{ typography: 'h5', marginLeft: '20px' }}>
              All Notes
            </Box>
          </div>
          <div className={classes.dialogContentContainer}>
            {vendorAuthNotes &&
              <>
                {vendorAuthNotes.map((note) => renderNote(note))}
              </>
            }
          </div>

        </Box>
      </Modal>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({});

export default compose(
  connect(mapStateToProps, {
    getAllVendorAuthNotesByAuthId,
    postVendorAuthNote,
    showToast,
  }), withTheme)(NotesModal);
  
import {
  DELETE_ASSIGNMENT_EXTENSIONS_FAILURE,
  GET_ASSIGNMENT_EXTENSIONS_FAILURE,
  GET_ASSIGNMENT_TRIPS_FAILURE,
  POST_ASSIGNMENT_EXTENSIONS_FAILURE,
  UPDATE_ASSIGNMENT_EXTENSIONS_FAILURE
} from 'modules/authorizations/store/types/globalTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  deleteAssignmentExtension,
  getAssignmentExtensions,
  postAssignmentExtension,
  postGlobalInfo,
  updateAssignmentExtension,
  updateGlobalInfo
} from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';

import CrudTableCard from 'modules/common/crudTableCard.component';
import Spinner from 'modules/common/spinner.component';

const AssignmentExtensionsCrud = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { globalExtensions, id, isReadOnly } = props;

  const tabs = [{ header: 'Assignment Extensions', order: 1, iconPath: ['fas', 'suitcase-rolling'] }];

  const tripOptions = [
    { title: 'Bereavment', id: 1 },
    { title: 'Business', id: 2 },
    { title: 'Evacuation', id: 3 },
    { title: 'Ext-Business', id: 4 },
    { title: 'Ext-Long', id: 5 },
    { title: 'Ext-Short', id: 6 },
    { title: 'Trip Home', id: 7 },
    { title: 'Visa Renewal Trip', id: 8 }
  ];

  const assignmentExtensionsColumns = [
    {
      accessorKey: 'extensionDate',
      header: 'Extension Date',
      type: 'date',
      required: true,
      size: 70,
      Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : ''
    },
    {
      accessorKey: 'revEndDate',
      header: 'Rev End Date',
      type: 'date',
      required: false,
      size: 50,
      Cell: ({ cell }) => cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : ''
    },
    {
      accessorKey: 'extensionType',
      header: 'Extension Type',
      type: 'dropdown-v2',
      required: false,
      options: tripOptions,
      size: 50,
      Cell: ({ cell }) =>
        cell.row.original.extensionType != null && <p>{tripOptions.find(o => o.id === cell.row.original.extensionType).title}</p>
    },
    { accessorKey: 'extensionNote', header: 'Extension Note', type: 'text', required: false, size: 50 }
  ];

  const updateExtension = async (vendor, values) => {
    let request = {...values};
    request.authorizationId = id;
    request.assignmentExtensionId = vendor.original.assignmentExtensionId;

    request.extensionDate = (!values.extensionDate || values.extensionDate === '') ? null : values.extensionDate
    request.revEndDate = (!values.revEndDate || values.revEndDate === '') ? null : values.revEndDate
    request.extensionType = (!values.extensionType || values.extensionType === '') ? null : values.extensionType;

    setIsLoading(true);
    const resp = await props.updateAssignmentExtension(request);
    if (resp.type === UPDATE_ASSIGNMENT_EXTENSIONS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getAssignmentExtensions(id);
      setIsLoading(false);
      if (getResp.type === GET_ASSIGNMENT_EXTENSIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createExtension = async values => {
    let request = {...values};
    request.authorizationId = id;

    request.extensionDate = (!values.extensionDate || values.extensionDate === '') ? null : values.extensionDate
    request.revEndDate = (!values.revEndDate || values.revEndDate === '') ? null : values.revEndDate
    request.extensionType = (!values.extensionType || values.extensionType === '') ? null : values.extensionType;

    setIsLoading(true);
    const resp = await props.postAssignmentExtension(request);
    if (resp.type === POST_ASSIGNMENT_EXTENSIONS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getAssignmentExtensions(id);
      setIsLoading(false);
      if (getResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteExtension = async vendor => {
    const resp = await props.deleteAssignmentExtension(vendor.original.assignmentExtensionId);
    if (resp.type === DELETE_ASSIGNMENT_EXTENSIONS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAssignmentExtensions(id);
      if (getResp.type === GET_ASSIGNMENT_EXTENSIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const summaries = [
    {
      adjustedColumns: assignmentExtensionsColumns,
      rows: globalExtensions ? globalExtensions : [],
      addButtonText: 'Add Extension',
      tab: 1,
      updateRow: updateExtension,
      createRow: createExtension,
      deleteRow: deleteExtension,
      hideEdit: isReadOnly
    }
  ];

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && <CrudTableCard tabs={tabs} summaries={summaries} numColumns={5} />}
    </>
  );
};

export default compose(
  connect(null, {
    updateGlobalInfo,
    postGlobalInfo,
    showToast,
    updateAssignmentExtension,
    postAssignmentExtension,
    deleteAssignmentExtension,
    getAssignmentExtensions
  })
)(AssignmentExtensionsCrud);

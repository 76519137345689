import {
  Box,
  Button,
} from '@mui/material';
import { POST_COMMUNICATION_INFO_FAILURE, UPDATE_COMMUNICATION_INFO_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postCommunicationInfo, updateCommunicationInfo } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const CommunicationCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { clientInfo, communication } = props;

  const communicationTabs = [
    { header: 'Communication', order: 1, iconPath: ['fas', 'phone'] },
  ];

  const communicationSummaries = [
    { header: 'Preferred Contact', accessorKey: 'preferredContact', type: 'dropdown', required: true, options: ['Mobile', 'Email'], value: communication ? communication.preferredContact : '', tab: 1 },
    { header: 'Primary Number', accessorKey: 'primaryNumber', type: 'text', required: false, value: communication ? communication.primaryNumber : '', tab: 1 },
    { header: 'Primary Email', accessorKey: 'primaryEmail', type: 'text', required: false, value: communication ? communication.primaryEmail : '', tab: 1 },
    { header: 'Text Messaging', accessorKey: 'textMessaging', type: 'checkbox', required: false, value: communication ? communication.textMessaging : '', tab: 1 },
    { header: 'Significant Other Mobile', accessorKey: 'soMobile', type: 'text', required: false,  value: communication ? communication.soMobile : '', tab: 1 },
    { header: 'Work Email', accessorKey: 'workEmail', type: 'text', required: false, value: communication ? communication.workEmail : '', tab: 1 },
    { header: 'Significant Other Personal Email', accessorKey: 'soPersonalEmail', type: 'text', required: false, value: communication ? communication.soPersonalEmail : '', tab: 1 },
  ];

  const topLink = clientInfo ? { title: clientInfo.description, link: `/clients/${clientInfo.moveTrackClientId}` } : null;

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    values.authorizationId = props.id;
        
    if (communication && communication.length !== 0) {
      const resp = await props.updateCommunicationInfo(values);
      if (resp.type === UPDATE_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update the employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postCommunicationInfo(values);
      if (resp.type === POST_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const SendEeEmailButton = ({ ...rest }) => {
    
    return (
      <>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 10px',
                }}
              >
                  <Button
                    variant="contained"
                    sx={{
                      maxHeight: '40px',
                      marginLeft: '12px',
                      marginTop: '8px',
                      backgroundColor: '#868686',
                      '&:hover': { backgroundColor: '#474747' },
                    }}
                  >
                    Send EE Email
                  </Button>
              </Box>
            </Box>
          </Box>
      </>
    );
  };
    
  return (
    <EditableCard
      tabs={communicationTabs}
      summaries={communicationSummaries}
      numColumns={2} 
      updateFields={updateFields}
      isLoading={isLoading}
      ButtonComponent={SendEeEmailButton}
      topLink={topLink}
    />
  );

};

export default compose(
  connect(null, {
    postCommunicationInfo,
    updateCommunicationInfo,
    showToast,
  }),
)(CommunicationCard);
import { ValueType, YesOrNo, parseFormValue, vendorContactSearchInfo, vendorSearchInfo } from './formDataConstants';

const supplierSelectedByOptions = [
  { title: 'Client', id: 1 },
  { title: 'RC', id: 2 },
  { title: 'EE', id: 3 }
];

export const createGlobalDestinationPostRequest = (formData, authorizationId) => {
  const request = { ...formData };
  request.authorizationId = authorizationId;
  parseDestinationServiceFormValues(request, formData);
  return request;
};

export const createGlobalDestinationPutRequest = (originalFormData, newFormData, authorizationId) => {
  const request = { ...newFormData };
  request.authorizationId = authorizationId;
  request.authGlobalDestinationId = originalFormData.authGlobalDestinationId;
  request.bundled = newFormData?.bundled === YesOrNo[0];
  return request;
};

export const createGlobalDestinationRows = (formDataList, vendors, vendorContacts) => {
  if (!formDataList || !Array.isArray(formDataList)) {
    return [];
  }

  return formDataList.map(data => ({
    ...data,
    bundled: data?.bundled ? YesOrNo[0] : YesOrNo[1],
    initiationDate: parseFormValue(data.initiationDate, ValueType.DateTimeType),
    cancelledDate: parseFormValue(data.cancelledDate, ValueType.DateTimeType),
    completedDate: parseFormValue(data.completedDate, ValueType.DateTimeType),
    requestDate: parseFormValue(data.requestDate, ValueType.DateTimeType)
  }));
};

export const createGlobalDestinationColumns = (vendors, vendorContacts) => {
  return [
    //TODO - Dropdown
    {
      accessorKey: 'vendorId',
      header: 'Company',
      nameKey: 'companyName',
      searchInfo: vendorSearchInfo(vendors),
      type: 'advanced-search',
      required: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? vendors?.find(v => v.vendorId === cell.getValue())?.companyName : '')
    },
    {
      //TODO - Figure out how to filter this based on the selected company ...
      accessorKey: 'vendorContactId',
      header: 'Vendor Contact',
      nameKey: 'fullName',
      searchInfo: vendorContactSearchInfo(vendorContacts),
      type: 'advanced-search',
      required: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? vendorContacts?.find(v => v.vendorContactId === cell.getValue())?.fullName : '')
    },
    { accessorKey: 'bundled', header: 'Bundled', type: 'dropdown', required: true, options: YesOrNo, hideInTable: true, size: 65 },
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 65 },
    {
      accessorKey: 'initiationDate',
      header: 'InitationDate',
      type: 'date',
      required: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-')
    },
    {
      accessorKey: 'cancelledDate',
      header: 'Cancelled Date',
      type: 'date',
      hideInTable: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-')
    },
    {
      accessorKey: 'completedDate',
      header: 'Completed Date',
      type: 'date',
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-')
    },
    {
      accessorKey: 'requestDate',
      header: 'DSC Request Date',
      type: 'date',
      hideInTable: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-')
    },
    { accessorKey: 'daysAuthorized', header: 'Days Authorized', type: 'number', hideInTable: false, size: 65 },
    { accessorKey: 'daysUtilized', header: 'Days Utilized', type: 'number', hideInTable: true, size: 65 },
    {
      accessorKey: 'supplierSelectedById',
      header: 'Supplier Selected By',
      type: 'dropdown-v2',
      options: supplierSelectedByOptions,
      hideInTable: true,
      size: 65
    },
    { accessorKey: 'serviceNotes', header: 'Service Notes', type: 'text', hideInTable: true, size: 65 }
  ];
};

const parseDestinationServiceFormValues = (currentRequest, newRequestValues) => {
  currentRequest.bundled = newRequestValues.bundled === YesOrNo[0];
  currentRequest.initiationDate = parseFormValue(newRequestValues.initiationDate, ValueType.DateTimeType);
  currentRequest.cancelledDate = parseFormValue(newRequestValues.cancelledDate, ValueType.DateTimeType);
  currentRequest.completedDate = parseFormValue(newRequestValues.completedDate, ValueType.DateTimeType);
  currentRequest.requestDate = parseFormValue(newRequestValues.requestDate, ValueType.DateTimeType);
  currentRequest.daysAuthorized = parseFormValue(newRequestValues.daysAuthorized, ValueType.FloatType);
  currentRequest.daysUtilized = parseFormValue(newRequestValues.daysUtilized, ValueType.FloatType);
  currentRequest.fee = parseFormValue(newRequestValues.fee, ValueType.FloatType);
};

import { keyBy } from 'lodash';
import { getAlpha2ByAlpha3 } from 'country-locale-map';
import dot from 'dot-object';

export const remapMetadata = (metadata) => {
  return [
    'basementFinishTypes',
    'commentRegardingTypes',
    'commentServiceTypes',
    'contactInfoOwnerTypes',
    'contactPreferenceTypes',
    'contactTypes',
    'emailTypes',
    'familiarWithAreaTypes',
    'hoaPaymentScheduleTypes',
    'homesaleTypes',
    'houseHeatTypes',
    'hhgMoveTypes',
    'houseInspectionTypes',
    'housePoolTypes',
    'housePropertyTypes',
    'houseRoofStyleTypes',
    'houseSewageTypes',
    'houseSidingTypes',
    'houseStyleTypes',
    'houseWaterSourceTypes',
    'maritalStatuses',
    'moveReasons',
    'moveTypes',
    'petTypes',
    'phoneTypes',
    'preferenceTypes',
    'propertyCreditBalanceTypes',
    'reloPolicyTypes',
    'taxFilingStatuses',
    'solarPanelOwnTypes',
  ].reduce((remappedData, metadataType) => {
    if (metadata[metadataType]) {
      metadata[metadataType] = metadata[metadataType].filter((md) => md.isActive);
    }
    remappedData[metadataType] = keyBy(metadata[metadataType], 'id');
    return remappedData;
  }, {});
};

const parseKey = (key, removePrefix) => {
  const parts = key.split('.').map((part) => part.charAt(0).toLowerCase() + part.substring(1));
  return (parts.length > 1 && parts[0] === removePrefix ? parts.splice(1): parts).join('.');
};

export const remapValidationResults = (response) => {
  return response.reduce((results, section) => {
    return {
      ...results,
      [section.name]: section.result.errors.reduce((errors, error) => {
        const key = parseKey(error.propertyName, section.name);
        return {
          ...errors,
          ...dot.object({
            [key]: error.errorMessage,
          }),
        };
      }, {}),
    };
  }, {});
};

export const parseRocketInfo = (intakeRecord, rcInfo, policyCode) => {
  const mainContact = intakeRecord.contacts[0];
  const secondaryContact = intakeRecord.contacts[1];
  const { departureProperty, phoneNumbers } = intakeRecord;

  const homePhone = phoneNumbers.find((o) => o.phoneTypeId === 0)?.phoneNumber.replace('+1', '').replace(/\D/g, '');
  let cellPhone = phoneNumbers.find((o) => o.phoneTypeId === 1)?.phoneNumber.replace('+1', '').replace(/\D/g, '');
  // let spouseCellPhone = phoneNumbers.find((o) => o.phoneTypeId === 1 && o.contactInfoOwnerTypeId === 2)?.phoneNumber;
  // const workPhone = phoneNumbers.find((o) => o.phoneTypeId === 2)?.phoneNumber;
  
  // cellPhone = parseInt(cellPhone?.replace('+1', '').replace(/\D/g, ''));
  // spouseCellPhone = parseInt(spouseCellPhone?.replace('+1', '').replace(/\D/g, ''));

  let returnBody = {
    applicants: [
      {
        type: 'primary',
        firstName: mainContact.firstName,
        lastName: mainContact.lastName,
        email: intakeRecord.emailAddresses[0]?.emailAddress,
        phoneNumber: homePhone ? homePhone.toString() : cellPhone.toString(),
        cellPhone : cellPhone ? cellPhone.toString() : '',
        currentResidence: {
          address: {
            addressLine1: departureProperty.address1,
            city: departureProperty.city,
            state: departureProperty.stateCode,
            zipCode: departureProperty.postalCode,
            country: getAlpha2ByAlpha3(departureProperty.countryCode),
          },
        },
        income: {
          currentEmployment: [{
            employerName: intakeRecord.client.description,
          }],
        },
      }
    ],
    languageCode: intakeRecord.mortgageSpanishSpeaking ? 'spa' : 'eng',
    relocation: {
      authCode: intakeRecord.mtAuthorizationId,
      reloConsultantEmail: rcInfo.email,
      isDirectBill: intakeRecord.mortgageDirectBillEligible ? true : false,
    },
    employerName: intakeRecord.client.description,
    comments: intakeRecord.mortgageLeadMessage,
    subjectProperty: {
      desiredLocation: {
        city: intakeRecord.destinationCity,
        state: intakeRecord.destinationStateCode,
      },
    },
  }

  if ((secondaryContact?.firstName && secondaryContact?.firstName.length > 0) && (secondaryContact?.lastName && secondaryContact?.firstName.lastName > 0) && (intakeRecord.mortgageCoApplicantPhone && intakeRecord.mortgageCoApplicantPhone.length > 0)) {
    returnBody.applicants.push({
      type: 'coApplicant',
      firstName: secondaryContact?.firstName ? secondaryContact.firstName : '',
      lastName: secondaryContact?.lastName ? secondaryContact.lastName : '',
      email: intakeRecord.mortgageCoApplicantEmail,
      cellPhone: intakeRecord.mortgageCoApplicantPhone ? intakeRecord.mortgageCoApplicantPhone.replace('+1', '').replace(/\D/g, '') : undefined,
    });
  }

  return returnBody;
};

export const parsePncPermiaInfo = (intakeRecord) => {
  return {
    mtAuthorizationId: intakeRecord.mtAuthorizationId,
    relocationConsultantId: intakeRecord.relocationConsultantId,
    intakeRecordStatusId: intakeRecord.intakeRecordStatusId,
    clientId: intakeRecord.clientId,
    client: {
      id: intakeRecord.client.id,
      description: intakeRecord.client.description,
      moveTrackClientId: intakeRecord.client.moveTrackClientId,
    },
    departureCity: intakeRecord.departureCity,
    departureStateCode: intakeRecord.departureStateCode,
    destinationCity: intakeRecord.destinationCity,
    destinationStateCode: intakeRecord.destinationStateCode,
    relocationPolicyCodeId: intakeRecord.relocationPolicyCodeId,
    newJobStartDate: intakeRecord.newJobStartDate,
    contacts: intakeRecord.contacts,
    phoneNumbers: intakeRecord.phoneNumbers,
    emailAddresses: intakeRecord.emailAddresses,
    departureProperty: {
      isRental: intakeRecord.departureProperty.isRental,
      address1: intakeRecord.departureProperty.address1,
      address2: intakeRecord.departureProperty.address2,
      address3: intakeRecord.departureProperty.address3,
      stateCode: intakeRecord.departureProperty.stateCode,
      city: intakeRecord.departureProperty.city,
      postalCode: intakeRecord.departureProperty.postalCode,
      countryCode: intakeRecord.departureProperty.countryCode,
    },
    id: intakeRecord.id,
    Subsidy: false, // TODO: Add field to intakeRecord
    InternationalCredit: false, // TODO: Add field to intakeRecord
    // directBillComment: 'Yes - typical customary non-recurring buyer costs', // TODO: Add field to intakeRecord
    spanishSpeaking: intakeRecord.mortgageSpanishSpeaking ?? false,
    transfereeStatus: intakeRecord.mortgageStatusOfTransferee ?? 3, // Default to Unkown
    benefitCap: intakeRecord.mortgageBenefitsCap ?? false,
    benefitCapAmount: intakeRecord.mortgageAmountOfCap,
    additionalComment: intakeRecord.mortgageLeadMessage,
    closingCostMethod: intakeRecord.mortgageClosingCostMethod ?? 5, // Default to Unkown
    coApplicantEmail: intakeRecord.mortgageCoApplicantEmail,
    coApplicantPhone: intakeRecord.mortgageCoApplicantPhone,
    relocationType: intakeRecord.mortgageTypeOfRelocation ?? 1, // Default to unkown
    directBillEligible: intakeRecord.mortgageDirectBillEligible ? intakeRecord.mortgageDirectBillEligible.toString() : 'No',
  };
};

export const mortgageLeadsAreValid = (leads, moveType) => {
  if (moveType === 1) {
    if (leads.dynamicVendors.length < leads.staticVendors.length && (leads.reason === null || leads.reason?.length === 0 || (/^\s*$/.test(leads.reason)))) {
      return false;
    } else if (leads.dynamicVendors.length > leads.staticVendors.length)
    {
      if ((leads.closingCostMethod?.length === 0 || leads.typeOfRelocation?.length === 0 || leads.statusOfTransferee?.length === 0 || leads.directBillEligible?.length === 0 || (leads.benefitsCap && leads.amountOfCap?.length === 0)))
      {
        return false; 
      }
      return true;
    } 
    return true;
  } else {
    return true;
  }
};
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';
import { updateVendor } from 'modules/authorizations/store/actions/dataManagement.actions';
import { UPDATE_VENDORS_REQUEST } from 'modules/authorizations/store/types/dataManagementTypes';

const VendorTaxesEditableCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { vendorDetails, isReadOnly } = props;

  const taxesTab = [{ header: 'Taxes and Other Info', order: 1, iconPath: ['fas', 'file-contract'] }];

  const termsOptions = ['None', 'NET 0', 'NET 1', 'NET 5', 'NET 7', 'NET 10', 'NET 15', 'NET 30']

  const taxesSummaries = [
    {
      header: 'Taxes Id',
      accessorKey: 'taxesId',
      type: 'text',
      required: false,
      value: vendorDetails?.taxesId ?? '',
      tab: 1
    },
    {
      header: 'Terms',
      accessorKey: 'terms',
      type: 'dropdown',
      required: false,
      value: vendorDetails?.terms ?? '',
      tab: 1,
      options: termsOptions
    },
    {
      header: 'Form 1099',
      accessorKey: 'form1099',
      type: 'text',
      required: false,
      value: vendorDetails?.form1099 ?? '',
      tab: 1
    },
    {
      header: 'Box 1099',
      accessorKey: 'box1099',
      type: 'text',
      required: false,
      value: vendorDetails?.box1099 ?? '',
      tab: 1
    },
    {
      header: 'Check Amount',
      accessorKey: 'checkAmount',
      type: 'number',
      required: false,
      value: vendorDetails?.checkAmount,
      tab: 1
    },
    {
      header: 'SE Org_UID',
      accessorKey: 'seOrgUID',
      type: 'text',
      required: false,
      value: vendorDetails?.seOrgUID ?? '',
      tab: 1
    },
    {
      header: 'Ap Tracking CR',
      accessorKey: 'apTrackingCR',
      type: 'text',
      required: false,
      value: vendorDetails?.apTrackingCR ?? '',
      tab: 1
    }
  ];

  const updateTaxesTab = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...vendorDetails };

    newValues.taxesId = values.taxesId;
    newValues.terms = values.terms;
    newValues.form1099 = values.form1099;
    newValues.box1099 = values.box1099;
    newValues.checkAmount = values.checkAmount;
    newValues.accountNumber = values.accountNumber;
    newValues.seOrgUID = values.seOrgUID;
    newValues.apTrackingCR = values.apTrackingCR;

    const resp = await props.updateVendor(newValues);
    if (resp.type === UPDATE_VENDORS_REQUEST) {
      props.showToast('Failed to update vendor details, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      setIsLoading(false);
      return true;
    }
  };

  return (
    <EditableCard
      tabs={taxesTab}
      summaries={taxesSummaries}
      numColumns={3}
      updateFields={updateTaxesTab}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );
};

export default compose(
  connect(null, {
    updateVendor,
    showToast
  })
)(VendorTaxesEditableCard);

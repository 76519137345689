/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { showToast } from 'modules/layout/layout.actions';
import SpinnerComponent from 'modules/common/spinner.component';

const VoucherButtons = props => {
  const {
    isFormValidForApproval, //Drive the aprove all button
    voucherStatus,
    setVoucherStatus,
    updateVoucher, //Func to update voucher info
    voucherInfoForm, //State of the voucher form
    history,
    updateAllLineItems,
    expenseLineItems,
    isLoading,
    isDirty,
    setVoucherInfoForm, //Update the state of the voucher form
    voucherInfo //Current state of voucher form, before saving.
  } = props;

  const [deniedReason, setDeniedReason] = useState(voucherInfoForm.denyReason);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleDenyButton = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDeniedReason('');
  };

  const updateVoucherStatus = async status => {
    let updatedForm;

    switch (status) {
      case 1: // Draft
        updatedForm = { ...voucherInfoForm, status: 1 };
        break;
      case 2: // Pending
        updatedForm = { ...voucherInfoForm, status: 2 };
        break;
      case 4: // Approved
        updatedForm = { ...voucherInfoForm, status: 4 };
        break;
      case 5: // Denied
        updatedForm = { ...voucherInfoForm, status: 5, denyReason: deniedReason };
        break;
      default:
        updatedForm = { ...voucherInfoForm };
        break;
    }

    try {
      let resp = await updateVoucher(updatedForm);
      if (resp && status > 0) {
        setVoucherStatus(status);
        if (status === 2) {
          history.replace('/vouchers?status=2');
        }

        if (status === 4 && expenseLineItems && expenseLineItems.length > 0) {
          let tempExpenseLines = expenseLineItems.map(items => {
            return { ...items, approvalStatus: 'Approved' };
          });
          await updateAllLineItems(tempExpenseLines);
        }
      }
    } catch (error) {
      console.error('Failed to update voucher:', error);
    }
  };

  const createSaveAsDraftButton = () => {
    return (
      <Button
        color="primary"
        variant="contained"
        sx={{ maxHeight: '40px', marginRight: '12px', minWidth: '120px' }}
        onClick={() => updateVoucherStatus()}
        disabled={!isDirty}
      >
        Save Draft
      </Button>
    );
  };

  const createSaveAsPendingButton = () => {
    return (
      <Button
        color="primary"
        variant="contained"
        sx={{
          maxHeight: '40px',
          marginRight: '12px',
          minWidth: '120px',
          backgroundColor: voucherStatus === 1 ? '#5D9878' : 'gray',
          color: 'white',
          '&:hover': {
            backgroundColor: voucherStatus === 1 ? '#528569' : '#707070'
          }
        }}
        onClick={() => updateVoucherStatus(2)}
      >
        Set As Pending
      </Button>
    );
  };

  const createSaveButton = () => {
    return (
      <Button
        color="primary"
        variant="contained"
        sx={{ backgroundColor: '#36939b', '&:hover': { backgroundColor: '#15585e' }, color: 'ffffff', minWidth: '120px' }}
        onClick={() => updateVoucherStatus(0)}
        disabled={!isDirty}
      >
        Save
      </Button>
    );
  };

  const createSaveAsApproveAllButton = () => {
    return (
      <Button
        color="primary"
        variant="contained"
        sx={{ minWidth: '120px', backgroundColor: '#5D9878', '&:hover': { backgroundColor: '#528569' } }}
        onClick={() => updateVoucherStatus(4)}
        disabled={!isFormValidForApproval}
      >
        {voucherStatus === 1 ? 'Submit and Approve' : 'Approve All'}
      </Button>
    );
  };

  const createSaveAsDenyButton = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          sx={{
            maxHeight: '40px',
            minWidth: '120px',
            marginRight: '12px',
            backgroundColor: 'gray',
            color: 'white',
            '&:hover': {
              backgroundColor: '#707070'
            }
          }}
          onClick={handleDenyButton}
        >
          Deny
        </Button>
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box sx={{ width: 500, padding: '24px', margin: 'auto', marginTop: '20vh', backgroundColor: 'white', borderRadius: '8px' }}>
            <Typography variant="h6" sx={{ marginBottom: '16px' }}>
              Enter Deny Reason
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={deniedReason}
              onChange={e => setDeniedReason(e.target.value)}
              placeholder="Please enter the reason for denying the voucher"
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button onClick={() => updateVoucherStatus(5)} color="primary" type="submit" variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    );
  };

  const createCancelButton = () => {
    return (
      <Button
        onClick={() => setVoucherInfoForm(voucherInfo)}
        sx={{ color: '#41B7C0', minWidth: '120px', '&:hover': { color: '#3d959c' } }}
        disabled={!isDirty}
      >
        Cancel
      </Button>
    );
  };

  const calculateBalance = (voucher, lineItems) => {
    const lineItemTotal = lineItems.reduce((total, item) => {
      return total + (parseFloat(item?.amount) || 0);
    }, 0);
  
    return (voucher?.amount ?? 0) - lineItemTotal;
  };

  const buildDraftView = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
      <Box sx={{ display: 'flex', gap: '8px' }}>
        {createCancelButton()}
        {createSaveAsDraftButton()}
        { expenseLineItems.length !== 0 && calculateBalance(voucherInfo, expenseLineItems) > 0 && voucherInfo.amount && voucherInfo.payrollDate && ((voucherInfo.payToVendor === true && voucherInfo.invoiceNumber) || voucherInfo.payToVendor === false) &&
          createSaveAsPendingButton()
        }
        {createSaveAsApproveAllButton()}
      </Box>
    </Box>
  );

  const buildPendingView = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Box>{createSaveAsDenyButton()}</Box>
      <Box sx={{ display: 'flex', gap: '8px' }}>
        {createCancelButton()}
        {createSaveButton()}
        {createSaveAsApproveAllButton()}
      </Box>
    </Box>
  );

  const buildOnHoldView = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', gap: '8px' }}>
        {createSaveAsDenyButton()}
        {createSaveAsPendingButton()}
      </Box>
      <Box sx={{ display: 'flex', gap: '8px' }}>
        {createCancelButton()}
        {createSaveButton()}
        {createSaveAsApproveAllButton()}
      </Box>
    </Box>
  );

  const buildDeniedView = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>{createSaveAsPendingButton()}</Box>
  );

  const buildButtons = () => {
    switch (voucherStatus) {
      case 1:
        return buildDraftView();
      case 2:
        return buildPendingView();
      case 3:
        return buildOnHoldView();
      case 5:
        return buildDeniedView();
      default:
        return (
          <Button
            color="primary"
            variant="contained"
            sx={{ maxHeight: '40px', marginRight: '12px' }}
            onClick={() => updateVoucherStatus(1)}
          >
            Reset Status (For Testing)
          </Button>
        );
    }
  };

  return (
    <>
      {isLoading && <SpinnerComponent />}
      {!isLoading && <>{buildButtons()}</>}
    </>
  );
};

export default compose(
  connect(null, {
    showToast
  })
)(VoucherButtons);

import { Button, Grid, MenuItem, Typography, withStyles } from '@material-ui/core';
import { Field } from 'formik';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Checkbox, Select, TextField } from 'modules/intake/form';
import { calculateDuration, calculateEndDate, formatDateForInput } from 'utilities/common';

import ColorIndicator from './colorIndicator.component';
import Colors from './colors';

import { COUNTRY_CODES } from 'modules/common/countryCodes';
import { convertPhoneNumber } from '../authorizationIntake/utils';

const styles = () => ({
  longTextfield: {
  },
  phoneField: {
    marginTop: 12,
    width: 20,
    container: 10,
  },
  countryCodeSelect: {
    width: 155,
  },
});

class HomesaleProcessForm extends PureComponent {

  handlePhoneNumberChange = (phoneNumber, rootPath) => {
    this.props.setFieldValue(`${rootPath}.homesaleProcess.preferredAgencyPhone`, convertPhoneNumber(phoneNumber.target.value, this.props.homesaleProcess.preferredAgencyPhoneCountry));
  }

  handleCountryCodeChange = (countryCode, rootPath) => {
    this.props.setFieldValue(`${rootPath}.homesaleProcess.preferredAgencyPhoneCountry`, countryCode.target.value);
    this.props.setFieldValue(`${rootPath}.homesaleProcess.preferredAgencyPhone`, convertPhoneNumber(this.props.homesaleProcess.preferredAgencyPhone, countryCode.target.value));
  }

  getAgentInfoDateValue = (rootPath) => {
    const todaysDate = new Date().toISOString();
    let date = todaysDate.substring(0, 10);
    // if no value exists in there, then check if checkbox is true and make todays date. Else do nothing.
    if(this.props.homesaleProcess.sendAgentInfoDate === null)
    {
      if(this.props.homesaleProcess.sendBrokerRequestLetter === true)
      {
        this.props.setFieldValue(`${rootPath}.homesaleProcess.sendAgentInfoDate`, date);
      }
    }
    // if a value already exists here, then check if checkbox is true and do nothing. Else make null.
    else{
      if(this.props.homesaleProcess.sendBrokerRequestLetter === false)
      {
        this.props.setFieldValue(`${rootPath}.homesaleProcess.sendAgentInfoDate`, null);
      }
    }
  }

  render() {
    const  { classes, onEnableService, isFormEnabled, homesaleProcess, rootPath, handleBlur, handleChange, homesaleType } = this.props;
    const isGbo = homesaleType && homesaleType.description === 'GBO';

    let countryOptions = [];
    if (COUNTRY_CODES) {
      countryOptions = COUNTRY_CODES.map((country) => ({
        value: country['alpha-3'],
        label: country.name,
      }));
    }

    this.getAgentInfoDateValue(rootPath);

    return (
      <div className="form-group">
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography gutterBottom variant="h3" className={isFormEnabled ? '':  'disabled'}>Homesale Process</Typography>
          </Grid>
          <Grid item md={6}>
            <Button variant="outlined"
              color="secondary"
              onClick={onEnableService}
            >{
                isFormEnabled ? 'Disable Selling' : 'Enable Selling'
              }</Button>
          </Grid>
        </Grid>
        {
          isFormEnabled && 
            <>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.homesaleProcess.didExplainSteps`}
                  value={homesaleProcess.didExplainSteps}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Explain HMA, BVO, or GBO steps (fairly high-level)"
                />
              </div>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.homesaleProcess.didExplainPartnership`}
                  value={homesaleProcess.didExplainPartnership}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Explain partnership with Morreale, title, deed packages and STOLA information"
                />
              </div>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.homesaleProcess.didIntroduceAgents`}
                  value={homesaleProcess.didIntroduceAgents}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Introduce our agents and confirm the need for them"
                />
              </div>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.homesaleProcess.vetPreferredAgent`}
                  value={homesaleProcess.vetPreferredAgent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="EE wishes us to vet their preferred agent"
                />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.sendAgentInfoDate`}
                  label="Broker List Request Date"
                  value={homesaleProcess.sendAgentInfoDate}
                  disabled={true}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.homesaleProcess} />
                <Field
                  component={Checkbox}
                  name={`${rootPath}.homesaleProcess.sendBrokerRequestLetter`}
                  value={isFormEnabled && (homesaleProcess?.sendBrokerRequestLetter === null) ? true : homesaleProcess.sendBrokerRequestLetter }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Send Broker Request Letter"
                />
                { homesaleProcess.sendBrokerRequestLetter === false &&
                  <>
                    <Field
                      component={TextField}
                      name={`${rootPath}.homesaleProcess.reasonForNotSending`}
                      label="Reason for Not Sending"
                      value={homesaleProcess.reasonForNotSending}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </>
                }
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.preferredAgentName`}
                  label="Preferred Agent Name"
                  value={homesaleProcess.preferredAgentName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={homesaleProcess.vetPreferredAgent}
                />
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.preferredAgency`}
                  label="Preferred Agency"
                  value={homesaleProcess.preferredAgency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={homesaleProcess.vetPreferredAgent}
                />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.preferredAgencyEmail`}
                  label="Preferred Agency Email"
                  value={homesaleProcess.preferredAgencyEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Field
                  component={Select}
                  name={`${rootPath}.homesaleProcess.preferredAgencyPhoneCountry`}
                  label="Phone Country"
                  className={classes.countryCodeSelect}
                  value={homesaleProcess.preferredAgencyPhoneCountry}
                  onChange={(value) => this.handleCountryCodeChange(value, rootPath)}
                  onBlur={handleBlur}
                  labelWidth={40}
                >
                  {countryOptions.map((country) => (
                    <MenuItem
                      key={country.value}
                      value={country.value}
                    >
                      {country.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.preferredAgencyPhone`}
                  label="Phone Number"
                  value={convertPhoneNumber(homesaleProcess.preferredAgencyPhone, homesaleProcess.preferredAgencyPhoneCountry)}
                  onChange={() => this.handlePhoneNumberChange(rootPath)}
                  onBlur={handleBlur}
                />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.preferredBmaAppointmentDate`}
                  label="Preferred BMA Appt"
                  value={homesaleProcess.preferredBmaAppointmentDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.homesaleProcess} />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.listingStartDate`}
                  label="Goal for Listing Date"
                  value={homesaleProcess.listingStartDate}
                  onChange={(event) => {
                    handleChange(event);
                    const endDate = formatDateForInput(calculateEndDate(event.target.value, homesaleProcess.listingLength));
                    this.props.setFieldValue(`${rootPath}.homesaleProcess.listingEndDate`, endDate);
                  }}
                  onBlur={handleBlur}
                  type="date"
                />
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.listingLength`}
                  label="Listing Length Req"
                  value={homesaleProcess.listingLength}
                  onChange={(event) => {
                    handleChange(event);
                    const endDate = formatDateForInput(calculateEndDate(homesaleProcess.listingStartDate, event.target.value));
                    if (endDate) {
                      this.props.setFieldValue(`${rootPath}.homesaleProcess.listingEndDate`, endDate);
                    }
                  }}
                  onBlur={handleBlur}
                  className={classes.longTextfield}
                  numeric
                  suffix=" days"
                />
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.listingEndDate`}
                  label="Listing End Date"
                  value={homesaleProcess.listingEndDate}
                  onChange={(event) => {
                    handleChange(event);
                    const duration = calculateDuration(homesaleProcess.listingStartDate, event.target.value);
                    this.props.setFieldValue(`${rootPath}.homesaleProcess.listingLength`, duration < 0 ? '' : duration);
                  }}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.homesaleProcess} />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.inspectionStartDate`}
                  label="Inspection Start Date"
                  value={homesaleProcess.inspectionStartDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  disabled={!isGbo}
                />
                <ColorIndicator color={Colors.homesaleProcess} />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.appraisalStartDate`}
                  label="Appraisal Start Date"
                  value={homesaleProcess.appraisalStartDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  disabled={!isGbo}
                />
                <ColorIndicator color={Colors.homesaleProcess} />
              </div>
              <div className="row">
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.approximateAcquisitionDate`}
                  label="Approx Acquisition Date"
                  value={homesaleProcess.approximateAcquisitionDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                />
                <ColorIndicator color={Colors.homesaleProcess} />
              </div>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.homesaleProcess.didAskForOccupants`}
                  value={homesaleProcess.didAskForOccupants}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Ask while the home is listed is anyone going to be home?"
                  alignCenter
                />
                <Field
                  component={TextField}
                  name={`${rootPath}.homesaleProcess.occupantsDuringListing`}
                  label="Who?"
                  value={homesaleProcess.occupantsDuringListing}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.homesaleProcess.didSetEquityExpectations`}
                  value={homesaleProcess.didSetEquityExpectations}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Set expectations on equity based on Client or TRC Funded BROP"
                />
              </div>
              <div className="row">
                <Field
                  component={Checkbox}
                  name={`${rootPath}.homesaleProcess.didIncludeSpecificBenefits`}
                  value={homesaleProcess.didIncludeSpecificBenefits}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Include any HS-specific benefits like HS marketing allowance, HS bonus, etc"
                />
              </div>
            </>
        }
      </div>
    );
  }
}

HomesaleProcessForm.propTypes = {
  classes: PropTypes.object.isRequired,
  homesaleProcess: PropTypes.object.isRequired,
  homesaleType: PropTypes.object,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onEnableService: PropTypes.func.isRequired,
  isFormEnabled: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
)(HomesaleProcessForm);

import { postCommunicationInfo, updateCommunicationInfo } from '../store/actions/authorizations.actions';
import { POST_COMMUNICATION_INFO_FAILURE, UPDATE_COMMUNICATION_INFO_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';
import NotesAndCommunicationEditableCard from './notesAndCommunicationEditableCard.component';

const NotesAndCommunication = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const authServices = props.authServices ? props.authServices : {};
  const communication = props.communication ? props.communication : {};


const updateFields = async (tab, values) => {
    setIsLoading(true);

    values.authorizationId = props.id;
        
    if (communication && communication.length !== 0) {
        const resp = await props.updateCommunicationInfo(values);
        if (resp.type === UPDATE_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update the employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
        } else { 
        setIsLoading(false);
        return true; 
        }
    } else {
        const resp = await props.postCommunicationInfo(values);
        if (resp.type === POST_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
        } else { 
        setIsLoading(false);
        return true; 
        }
    }
    };
    
  return (
    <NotesAndCommunicationEditableCard
      communication={communication}
      servicesInfo={authServices}
      numColumns={2} 
      updateFields={updateFields}
      isLoading={isLoading}
    />
  );

};

export default compose(
  connect(null, {
    postCommunicationInfo,
    updateCommunicationInfo,
    showToast,
  }),
)(NotesAndCommunication);
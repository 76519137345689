
import { POST_FINANCE_AND_TAX_FAILURE, UPDATE_FINANCE_AND_TAX_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postFinanceAndTax, updateFinanceAndTax } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const FinanceAndTaxCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { details, clientTaxPolicies } = props;

  const summaryTabs = [
    { header: 'Finance and Tax', order: 1, iconPath: ['fa', 'dollar'] },
  ];

  const taxAssistancePolicySearchInfo = {
    searchingColumns: [{ name: 'id', title: 'Id' }, { name: 'policyCode', title: 'Policy Code' }, { name: 'description', title: 'Description' }],
    searchingColumnExtensions: [{ columnName: 'id', width: 150 }, { columnName: 'policyCode', width: 200 }, { columnName: 'description', width: 300 }],
    searchingRows: clientTaxPolicies ? clientTaxPolicies : {},
    idKey: 'id',
    nameKey: 'policyCode',
  };

  const fields = [
    { header: 'Tax Assistance Policy', accessorKey: 'id', nameKey: 'policyCode', nameValue: details && details.financeAndTax ? details.financeAndTax.taxPolicyCode : '', type: 'advanced-search', searchInfo: taxAssistancePolicySearchInfo, required: false, value: details && details.financeAndTax ? details.financeAndTax.taxPolicyId : '', hideInCard: false, tab: 1 },
    { header: '1099', accessorKey: 'forms', type: 'text', required: false, value: details && details.financeAndTax ? details.financeAndTax.forms : '', tab: 1 },
    { header: 'Division', accessorKey: 'division', type: 'text', required: false, value: details && details.financeAndTax ? details.financeAndTax.division : '', tab: 1 },
    { header: 'AP GL #', accessorKey: 'glNumber', type: 'text', required: false, value: details && details.financeAndTax ? details.financeAndTax.glNumber : '', tab: 1 },
    { header: 'Short Term Assignment', accessorKey: 'shortTermAssignment', type: 'checkbox', required: false, value: details && details.financeAndTax ? details.financeAndTax.shortTermAssignment : '', tab: 1 },
    { header: 'Tax Filing Status', accessorKey: 'taxFilingStatus', type: 'dropdown', options: ['Single', 'Married'], required: false,  value: details && details.financeAndTax ? details.financeAndTax.taxFilingStatus : '', tab: 1 },
    { header: 'Pay Period Processing Group', accessorKey: 'payPeriodProcessingGroup', type: 'dropdown', options: ['Pay Group 1', 'Pay Group 2', 'Pay Group 3', 'Pay Group 4'], required: false, value: details && details.financeAndTax ? details.financeAndTax.payPeriodProcessingGroup : '', tab: 1 },
    { header: 'AP Vend #', accessorKey: 'vendorNumber', type: 'text', required: false, value: details && details.financeAndTax ? details.financeAndTax.vendorNumber : '', tab: 1 },

  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    values.authorizationId = props.id;
        
    if (details.financeAndTax && details.financeAndTax.length !== 0) {
      const resp = await props.updateFinanceAndTax(values);
      if (resp.type === UPDATE_FINANCE_AND_TAX_FAILURE) {
        props.showToast('Failed to update the employee finance and tax info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postFinanceAndTax(values);
      if (resp.type === POST_FINANCE_AND_TAX_FAILURE) {
        props.showToast('Failed to update employee finance and tax info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={summaryTabs}
      summaries={fields}
      numColumns={2} 
      updateFields={updateFields}
      isLoading={isLoading}
    />
  );

};

export default compose(
  connect(null, {
    postFinanceAndTax,
    updateFinanceAndTax,
    showToast,
  }),
)(FinanceAndTaxCard);
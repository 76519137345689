export const ClassificationMap = {
  T: 'T – Taxable',
  E: 'E – Excludable',
  V: 'V – Vendor Excludable',
  O: 'O – Other Service non-Tax Assistance',
  P: 'P - Property',
  F: 'F - FICA Only',
  MT: 'MT – Memo Taxable – On 4782, No GUP',
  N: 'N – Other Taxable, No GrossUp',
  MN: 'MN – Memo NonTaxable'
};

export const CategoryMap = {
  EA: 'EA – Equity Acquired',
  OE: 'OE – Other Expenses',
  PC: 'PC – Property Costs',
  PM: 'PM – Property Management',
  FR: 'FR – Funds Received',
  F: 'F – Fees',
  AC: 'AC – Acquisition',
  RC: 'RC - Recurring Carrying Costs',
  NC: 'NC – Non-Recurring Costs',
  DC: 'DC – Disposition',
  AD: 'AD – Administrative',
  IT: 'IT - Interest',
  HHG: 'HHG – House Hold Goods',
  TRIP: 'TRIP - Trip En Route',
  HH: 'HH - House Hunting',
  TL: 'TL - Temporary Living',
  OLDR: 'OLDR - Old Residence',
  NEWR: 'NEWR - New Residence',
  OTH: 'OTH - Other Expenses',
  TP: 'TP - Third Party'
};

export const transformExpenses = expenses => {
  if (!expenses || !Array.isArray(expenses)) return [];

  return expenses.map(expense => {
    const classificationFullName = ClassificationMap[expense.classification] || expense.classification;
    const categoryFullName = CategoryMap[expense.category] || expense.category;

    return {
      ...expense,
      classificationFullName,
      categoryFullName
    };
  });
};

export const CreateExpenseCodeSearchInfo = (expenseCodes, clientPreferredExpenseCodes, clientId) => {
  let searchingRows = transformExpenses(expenseCodes);

  const preferredExpenseCodesForClient =
    clientPreferredExpenseCodes && Array.isArray(clientPreferredExpenseCodes)
      ? clientPreferredExpenseCodes.filter(code => code.clientId === clientId)
      : [];
  const addClientPreferredColumn = preferredExpenseCodesForClient.length > 0;

  const searchingColumns = [
    { name: 'expenseCodeCode', title: 'Expense Code' },
    { name: 'expenseCodeDescription', title: 'Description' },
    { name: 'classificationFullName', title: 'Classification', type: 'dropdown', options: Object.values(ClassificationMap) },
    { name: 'categoryFullName', title: 'Category', type: 'dropdown', options: Object.values(CategoryMap) }
  ];

  const searchingColumnExtensions = [
    { columnName: 'expenseCodeCode', width: 150 },
    { columnName: 'expenseCodeDescription', width: 250 },
    { columnName: 'classificationFullName', width: 200 },
    { columnName: 'categoryFullName', width: 200 }
  ];

  if (addClientPreferredColumn) {
    searchingColumns.push({
      name: 'clientPreferred',
      title: 'Client Preferred',
      type: 'dropdown',
      options: Object.values(CategoryMap)
    });

    searchingColumnExtensions.push({
      columnName: 'clientPreferred',
      width: 200
    });

    searchingRows = searchingRows && Array.isArray(searchingRows) ? searchingRows : [];
    searchingRows = searchingRows.map(row => {
      const matchingPreferredCode = preferredExpenseCodesForClient.find(code => code.expenseCodeId === row.expenseCodeId);
      const clientPreferred = matchingPreferredCode ? matchingPreferredCode.category : '';
      return {
        ...row,
        clientPreferred
      };
    });
  }

  return {
    searchingColumns,
    searchingColumnExtensions,
    searchingRows: searchingRows,
    idKey: 'expenseCodeId',
    nameKey: 'expenseCodeCode'
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgEstimate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  Fab,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getAuthClientPoliciesListByClient, updateAuthorizationSummary } from '../store/actions/authorizations.actions';
import { UPDATE_AUTHORIZATIONS_REQUEST } from '../store/types/authorizationTypes';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

const HeaderCardModal = props => {
  const { open, onSubmit, isLoading, onClose, details, clientInfo } = props;
  const classes = useStyles();

  const [policyOptionsFull, setPolicyOptionsFull] = useState([]);
  const [policyOptions, setPolicyOptions] = useState([]);

  const [summaryState, setSummaryState] = useState({...details.summary});

  const [isDirty, setIsDirty] = useState(false);

  const moveTypes = ['Candidate', 'Intern', 'Domestic Permanent Move', 'Short Term Assignment', 'Long Term Assignment', 'International Move/Localization', 'Repatriation', 'Extended Business Travel'];
  const surveyTypes = ['HMA', 'BVO', 'GBO', 'MLSUM', 'Renter', 'Candidate', 'Lump Sum', 'Global', 'Property Management', 'Southwire Intern', 'Sandia Intern'];

  const onSubmitModal = e => {
    e.preventDefault();

    onSubmit(summaryState);
  };

  const getClientPolicyOptions = async () => {
    let policyOptionsList = [];
    let clientPoliciesByClient = details?.clientPoliciesByClient;
    
    if(!clientPoliciesByClient || clientPoliciesByClient.length === 0){
      let clientPoliciesListResp = await props.getAuthClientPoliciesListByClient(clientInfo.moveTrackClientId);
      clientPoliciesByClient = clientPoliciesListResp?.response;
    }
    setPolicyOptionsFull(clientPoliciesByClient);
    clientPoliciesByClient.map((p) => policyOptionsList.push({ id: p.id, title: p.clientPolicyDescription }));
    setPolicyOptions(policyOptionsList);
  };

  useEffect(() => {
    if (clientInfo) {
      getClientPolicyOptions();
    }
  }, [clientInfo]);

  useEffect(() => {
    if (summaryState) {
      if ( summaryState !== details?.summary) {
        setIsDirty(true);
      }
    }
  }, [
    summaryState
  ]);

  const clientLink = clientInfo ? { title: clientInfo.description, link: `/clients/${clientInfo.moveTrackClientId}` } : null;

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Estimates" key="Estimates" placement="right">
              <FontAwesomeIcon icon={['fas', 'money-check-dollar']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Estimates
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem'
            }}
          >
            <Grid container spacing={2}>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      File Number
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {details.summary?.authorizationId ? details.summary?.authorizationId :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Client Name
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {clientLink ? <Link to={clientLink.link}>{clientLink.title}</Link> : <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Origin
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {(details.departureAddress && details.departureAddress.city && details.departureAddress.state) ? `${details.departureAddress.city}, ${details.departureAddress.state}` :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Destination
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {(details.destinationAddress && details.destinationAddress.city && details.destinationAddress.state) ? `${details.destinationAddress.city}, ${details.destinationAddress.state}` :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Move Miles
                    </Typography>
                  </div>
                </Box>
                <Box>
                    <Select
                        labelId={'moveReason'}
                        key={'moveReason'}
                        label={''}
                        name={'moveReason'}
                        value={summaryState.moveReason}
                        margin="dense"
                        variant="standard"
                        sx={{ marginTop: '6px', marginBottom: '6px', width: '200px' }}
                        onChange={e => setSummaryState({ ...summaryState, moveReason: e.target.value })}
                        >
                        <MenuItem key={'Candidate'} value={'Candidate'} sx={{ margin: 'dense' }}>Candidate</MenuItem>
                        <MenuItem key={'New Hire'} value={'New Hire'} sx={{ margin: 'dense' }}>New Hire</MenuItem>
                        <MenuItem key={'Non Relo'} value={'Non Relo'} sx={{ margin: 'dense' }}>Non Relo</MenuItem>
                        <MenuItem key={'Transfer'} value={'Transfer'} sx={{ margin: 'dense' }}>Transfer</MenuItem>
                    </Select>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Policy
                    </Typography>
                  </div>
                </Box>
                <Box>
                    <Select
                        labelId={'clientPolicyId'}
                        key={'clientPolicyId'}
                        label={''}
                        name={'clientPolicyId'}
                        value={summaryState.clientPolicyId}
                        margin="dense"
                        variant="standard"
                        sx={{ marginTop: '6px', marginBottom: '6px', width: '200px' }}
                        onChange={(e) => setSummaryState({ ...summaryState, clientPolicyId: e.target.value })}
                    >
                        {policyOptions.map(opt => (
                            <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                {opt.title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Billing Policy
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {(policyOptionsFull && summaryState && policyOptionsFull.find((po) => po.id === summaryState.clientPolicyId)) ? policyOptionsFull.find((po) => po.id === summaryState.clientPolicyId).clientBillingPolicy :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      VIP
                    </Typography>
                  </div>
                </Box>
                <Box>
                    <Checkbox name="vip" label="Active" checked={summaryState.vip != null ? summaryState.vip : false} onClick={(e) => setSummaryState({ ...summaryState, vip: e.target.checked })} />
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Repayment Agreement
                    </Typography>
                  </div>
                </Box>
                <Box>
                    <Select
                        labelId={'repayAgreement'}
                        key={'repayAgreement'}
                        label={''}
                        name={'repayAgreement'}
                        value={summaryState.repayAgreement}
                        margin="dense"
                        variant="standard"
                        sx={{ marginTop: '6px', marginBottom: '6px', width: '200px' }}
                        onChange={(e) => setSummaryState({ ...summaryState, repayAgreement: e.target.value })}
                    >
                        <MenuItem key={'Yes'} value={'Yes'} sx={{ margin: 'dense' }}>Yes</MenuItem>
                        <MenuItem key={'No'} value={'No'} sx={{ margin: 'dense' }}>No</MenuItem>
                        <MenuItem key={'N/A'} value={'N/A'} sx={{ margin: 'dense' }}>N/A</MenuItem>
                    </Select>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Move Type
                    </Typography>
                  </div>
                </Box>
                <Box>
                    <Select
                        labelId={'moveType'}
                        key={'moveType'}
                        label={''}
                        name={'moveType'}
                        value={summaryState.moveType}
                        margin="dense"
                        variant="standard"
                        sx={{ marginTop: '6px', marginBottom: '6px', width: '200px' }}
                        onChange={(e) => setSummaryState({ ...summaryState, moveType: e.target.value })}
                    >
                        {moveTypes.map(opt => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                                {opt}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Move Reason
                    </Typography>
                  </div>
                </Box>
                <Box>
                    <Select
                        labelId={'moveReason'}
                        key={'moveReason'}
                        label={''}
                        name={'moveReason'}
                        value={summaryState.moveReason}
                        margin="dense"
                        variant="standard"
                        sx={{ marginTop: '6px', marginBottom: '6px', width: '200px' }}
                        onChange={e => setSummaryState({ ...summaryState, moveReason: e.target.value })}
                        >
                        <MenuItem key={'Yes'} value={'Yes'} sx={{ margin: 'dense' }}>Candidate</MenuItem>
                        <MenuItem key={'No'} value={'No'} sx={{ margin: 'dense' }}>New Hire</MenuItem>
                        <MenuItem key={'N/A'} value={'N/A'} sx={{ margin: 'dense' }}>Non Relo</MenuItem>
                        <MenuItem key={'N/A'} value={'N/A'} sx={{ margin: 'dense' }}>Transfer</MenuItem>
                    </Select>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Survey Type
                    </Typography>
                  </div>
                </Box>
                <Box>
                    <Select
                        labelId={'surveyType'}
                        key={'surveyType'}
                        label={''}
                        name={'surveyType'}
                        value={summaryState.surveyType}
                        margin="dense"
                        variant="standard"
                        sx={{ marginTop: '6px', marginBottom: '6px', width: '200px' }}
                        onChange={e => setSummaryState({ ...summaryState, surveyType: e.target.value })}
                        >
                          {surveyTypes.map(opt => (
                            <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                                {opt}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      File Type
                    </Typography>
                  </div>
                </Box>
                <Box>
                    <Select
                        labelId={'fileType'}
                        key={'fileType'}
                        label={''}
                        name={'fileType'}
                        value={summaryState.fileType}
                        margin="dense"
                        variant="standard"
                        sx={{ marginTop: '6px', marginBottom: '6px', width: '200px' }}
                        onChange={(e) => setSummaryState({ ...summaryState, fileType: e.target.value })}
                    >
                        <MenuItem key={'Domestic'} value={'Domestic'} sx={{ margin: 'dense' }}>Domestic</MenuItem>
                        <MenuItem key={'International'} value={'International'} sx={{ margin: 'dense' }}>International</MenuItem>
                    </Select>
                </Box>
              </Grid>
              <Grid item md="3">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Move CAP
                    </Typography>
                  </div>
                </Box>
                <Box>
                    <TextField
                        key={'moveCap'}
                        label={''}
                        name={'moveCap'}
                        value={summaryState.moveCap}
                        margin="dense"
                        sx={{ maxWidth: '360px' }}
                        variant="standard"
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        allowDecimal={true}
                        onChange={e => setSummaryState({ ...summaryState, moveCap: e.target.value })}
                    />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained" disabled={!isDirty}>
              Save
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const HeaderCard = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);
  const [policyOptionsFull, setPolicyOptionsFull] = useState([]);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const { estimateInfo, details, clientInfo } = props;
  
  const getClientPolicyOptions = async () => {
    let policyOptionsList = [];
    let clientPoliciesByClient = details?.clientPoliciesByClient;
    
    if(!clientPoliciesByClient || clientPoliciesByClient.length === 0){
      let clientPoliciesListResp = await props.getAuthClientPoliciesListByClient(clientInfo.moveTrackClientId);
      clientPoliciesByClient = clientPoliciesListResp?.response;
    }
    setPolicyOptionsFull(clientPoliciesByClient);
    clientPoliciesByClient.map((p) => policyOptionsList.push({ id: p.id, title: p.clientPolicyDescription }));
    // setPolicyOptions(policyOptionsList);
  };

  const updateFields = async values => {
    setIsLoading(true);

    let newVals = { ...values };

    const resp = await props.updateAuthorizationSummary(newVals);
    if (resp.type === UPDATE_AUTHORIZATIONS_REQUEST) {
      props.showToast('Failed to update the auth summary info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else {
      setIsLoading(false);
      return true;
    }
  };

  const handleSaveEdits = async values => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  useEffect(() => {}, [estimateInfo]);

  useEffect(() => {
    if (clientInfo) {
      getClientPolicyOptions();
    }
  }, [clientInfo]);

  const clientLink = clientInfo ? { title: clientInfo.description, link: `/clients/${clientInfo.moveTrackClientId}` } : null;

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ border: details.summary?.vip ? '5px solid #f0b11d' : ''}}>
        <Box>
          {/* <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'money-check-dollar']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Estimates
                </Typography>
              </div>
              {!props.hideEdit && (
                <>
                  <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                    <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                  </Fab>
                </>
              )}
            </div>
          </CardContent> */}
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2} columns={17}>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      File Number
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {details.summary?.authorizationId ? details.summary?.authorizationId :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Client Name
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {clientLink ? <Link to={clientLink.link}>{clientLink.title}</Link> : <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Origin
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {(details.departureAddress && details.departureAddress.city && details.departureAddress.state) ? `${details.departureAddress.city}, ${details.departureAddress.state}` :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Destination
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {(details.destinationAddress && details.destinationAddress.city && details.destinationAddress.state) ? `${details.destinationAddress.city}, ${details.destinationAddress.state}` :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Move Miles
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {Math.floor(Math.random() * 5000)}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Full Name
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {details.summary?.transfereeName ? details.summary?.transfereeName :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Policy
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {(policyOptionsFull && details.summary.clientPolicyId && policyOptionsFull.find((po) => po.id === details.summary.clientPolicyId)) ? policyOptionsFull.find((po) => po.id === details.summary.clientPolicyId).clientPolicyDescription :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Billing Policy
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {(policyOptionsFull && details.summary.clientPolicyId && policyOptionsFull.find((po) => po.id === details.summary.clientPolicyId)) ? policyOptionsFull.find((po) => po.id === details.summary.clientPolicyId).clientBillingPolicy :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="1">
                <Box>
                  <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                    <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                  </Fab>
                </Box>
              </Grid>

              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      VIP
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {details.summary?.vip ? 'Yes' :  'No'}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2" sx={{ border: (details.summary?.repayAgreement && details.summary?.repayAgreement === 'Yes') ? '5px solid #67a783' : '5px solid #de3f18' }}>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Repay Agreement
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {details.summary?.repayAgreement ? details.summary?.repayAgreement :  <div>&mdash;</div>} 
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Move Type
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {details.summary.moveType ? details.summary?.moveType :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Move Reason
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {details.summary.moveReason ? details.summary?.moveReason :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Survey Type
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                        {details.summary?.surveyType ? details.summary?.surveyType :  <div>&mdash;</div>} 
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      File Type
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      {details.summary?.fileType ? details.summary?.fileType :  <div>&mdash;</div>} 
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Move CAP
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      ${details.summary?.moveCap ? details.summary?.moveCap :  <div>&mdash;</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                
              </Grid>
              <Grid item md="1">

              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <HeaderCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          estimateInfo={estimateInfo}
          details={details}
          clientInfo={clientInfo}
        />
      )}
    </ThemeProvider>
  );
};

export default compose(
  connect(null, {
    updateHhgEstimate,
    getAuthClientPoliciesListByClient,
    updateAuthorizationSummary,
    showToast
  })
)(HeaderCard);

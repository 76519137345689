import { ContentType } from 'modules/common/multiTabCard/multiTabCard.component';
import { createFamilyColumns, createFamilyHeaderRow, createFamilyRows, getNameOfDeleteRow } from './familyFormFields';
import { createFamilyPetColumns, createFamilyPetRows, getNameOfDeletePetRow } from './petFormFields';

/**
 * Creates the tab configuration for the multi-tab component.
 * @returns {Array} Array of tab configuration objects.
 */

export const createMultiTabComponentTabs = () => {
  return [
    {
      order: 1,
      contentType: ContentType.CrudTable,
      header: 'Family',
      iconPath: ['fas', 'people-arrows'],
      isDisabled: false,
      headerMessage: null,
      hideEdit: false,
    },
    {
      order: 2,
      contentType: ContentType.CrudTable,
      header: 'Pets',
      iconPath: ['fas', 'fa-dog'],
      isDisabled: false,
      headerMessage: null,
      hideEdit: false,
    },
  ];
};

/**
 * Creates the content configuration for the multi-tab component.
 * @param {Object} clientInfo - Information about the client.
 * @param {Object} employeeInfo - Information about the employee.
 * @param {Function} updateEmployeeFields - Function to update employee fields.
 * @param {boolean} isLoading - Loading state indicator
 * @param {Array} familyListInfo - Information about the family members.
 * @param {Function} updateFamilyCrudRowFunc - Function to update a row.
 * @param {Function} createFamilyCrudRowFunc - Function to create a new row.
 * @param {Function} deleteFamilyCrudRowFunc - Function to delete a row.
 * @param {Array} vendors - Array of vendors for Pet Service
 * @param {Array} petListInfo - Information about the pets.
 * @param {Function} updatePetCrudRowFunc - Function to update a row.
 * @param {Function} createPetCrudRowFunc - Function to create a new row.
 * @param {Function} deletePetCrudRowFunc - Function to delete a row.
 * @returns {Array} Array of content configuration objects.
 */
export const createMultiTabComponentContent = (
  clientInfo,
  employeeInfo,
  updateEmployeeFields,
  isLoading,
  familyListInfo,
  updateFamilyCrudRowFunc,
  createFamilyCrudRowFunc,
  deleteFamilyCrudRowFunc,
  vendors,
  petListInfo,
  updatePetCrudRowFunc,
  createPetCrudRowFunc,
  deletePetCrudRowFunc,
) => {
  return [
    {
      contentType: ContentType.CrudTable,
      tabOrder: 1,
      columns: createFamilyColumns(),
      rows: createFamilyRows(familyListInfo),
      updateRow: updateFamilyCrudRowFunc,
      createRow: createFamilyCrudRowFunc,
      deleteRow: deleteFamilyCrudRowFunc,
      getNameOfDeleteRow,
      customAddText: 'Add Family Member',
      headerRow: createFamilyHeaderRow(),
      summaries: null,
      numColumns: null,
      updateFields: null,
      isLoading,
      hideEdit: null,
      topLink: null,
    },
    {
      contentType: ContentType.CrudTable,
      tabOrder: 2,
      columns: createFamilyPetColumns(vendors),
      rows: createFamilyPetRows(petListInfo, vendors),
      updateRow: updatePetCrudRowFunc,
      createRow: createPetCrudRowFunc,
      deleteRow: deletePetCrudRowFunc,
      getNameOfDeleteRow: getNameOfDeletePetRow,
      customAddText: 'Add Pet',
      headerRow: null,
      summaries: null,
      numColumns: null,
      updateFields: null,
      isLoading,
      hideEdit: null,
      topLink: null,
    },
  ];
};

/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Accordion, AccordionDetails, AccordionSummary, Box, TextField, Typography, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import classNames from 'classnames';
import InlineToolTip from 'modules/common/inlineToolTip.component';
import ToolTip from 'modules/common/toolTip.component';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const TitleRepTable = (props) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
      classes={{ root: classNames(props.classes.accordionStyleContacts) }}
    >
      <AccordionSummary
        expandIcon={
          <Box color={theme.palette.secondary.main}>
            <ExpandMoreIcon color="inherit" />
          </Box>
        }
      >
        <div className={props.classes.newRow}>
          <h2>Testing Sandbox</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
            <Typography>Basic Tooltip: </Typography>

            <Box sx={{display: 'flex', flexDirection: 'row', padding: '10px'}}>
                <TextField
                    key={'effectiveDate'}
                    label={'Sample Field'}
                    name={'effectiveDate'}
                    required={false}
                    disabled={false}
                    sx={{ maxWidth: '360px' }}
                    variant="outlined"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                />
                <Box>
                    <Box sx={{paddingTop: '30px'}}>
                        <ToolTip label={'This is sample instructional text for the RC that will provide helpful tips throughout the intake form and beyond.'} />
                    </Box>
                </Box>
            </Box>

            <Typography>More Advanced Tooltip: </Typography>

            <Box sx={{display: 'flex', flexDirection: 'row', padding: '10px'}}>
                <TextField
                    key={'effectiveDate'}
                    label={'Sample Field'}
                    name={'effectiveDate'}
                    required={false}
                    disabled={false}
                    sx={{ maxWidth: '360px' }}
                    variant="outlined"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                />
                <Box>
                    <Box sx={{paddingTop: '20px'}}>
                        <InlineToolTip label={'This is sample instructional text for the RC that will provide helpful tips throughout the intake form and beyond.'} />
                    </Box>
                </Box>
            </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => {
  const { titleReps, isTitleRepIsLoading } = state.authorizations;
  return {
    titleReps,
    isTitleRepIsLoading,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
  }),
)(TitleRepTable);

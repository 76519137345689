/* eslint-disable no-useless-computed-key */

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';

import { setPageTitle, showToast } from 'modules/layout/layout.actions';
import Log from 'utilities/log';

import AuthServicesTable from './authServicesTable.component';
import AuthorizationSummariesTable from './authorizationSummariesTable.component';
import BrokerAgentsTable from './brokerAgentsTable.component';
import ClientPoliciesTable from './clientPoliciesTable.component';
import ExpenseCodesTable from './expenseCodesTable.component';
import InspectorsTable from './inspectorsTable.component';
import TaxPoliciesTable from './taxPoliciesTable.component';
import TitleRepTable from './titleRepTable.component';
import VendorContactsTable from './vendorContactsTable.component';
import VendorsTable from './vendorsTable.component';

import {
  getAuthClientPoliciesList, getAuthServices, getAuthorizationSummaries,
} from '../authorizations/store/actions/authorizations.actions';
import {
  getBrokerAgents, getExpenseCodes, getInspectors, getTaxPolicies, getTitleReps, getVendorContacts, getVendors,
} from 'modules/authorizations/store/actions/dataManagement.actions';

import {
  GET_ALL_AUTH_SERVICES_FAILURE, GET_AUTHORIZATIONS_FAILURE, GET_AUTH_LIST_CLIENT_POLICIES_FAILURE,
} from '../authorizations/store/types/authorizationTypes';
import {
  GET_ALL_BROKER_AGENTS_FAILURE, GET_EXPENSE_CODES_FAILURE, GET_INSPECTORS_FAILURE, GET_TAX_POLICIES_FAILURE, 
  GET_TITLE_REP_FAILURE, GET_VENDORS_FAILURE, GET_VENDOR_CONTACT_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../layout/types';
import { getExpenseLines, getExpenseSummaries, getVoucherSummaries } from 'modules/expenses/expenses.actions';
import { GET_EXPENSE_SUMMARIES_FAILURE, GET_EXPENSE_LINES_FAILURE, GET_VOUCHER_SUMMARIES_FAILURE } from 'modules/expenses/types';
import ExpenseSummariesTable from './expenseSummariesTable.component';
import ExpenseLineTable from './expenseLineTable.component';
import VoucherSummariesTable from './voucherSummariesTable.component';
import { getClients } from 'modules/clients/clients.actions';
import { GET_CLIENTS_FAILURE } from 'modules/clients/types';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import TestingSandbox from './testingSandbox.component';

const styles = (theme) => ({ 
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
});

class ReferentialDataManagementView extends PureComponent {
  state = {
    isLoading: false,
  };

  async componentDidMount() {
    this.props.setPageTitle('Referential Data Management');

    this.setState({isLoading: true});

    if (!this.props.authorizationSummaries) {
      let { type } = await this.props.getAuthorizationSummaries();
      if (type === GET_AUTHORIZATIONS_FAILURE) {
        this.props.showToast('Failed to retrieve authorizations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.allServices) {
      let { type } = await this.props.getAuthServices();
      if (type === GET_ALL_AUTH_SERVICES_FAILURE) {
        this.props.showToast('Failed to retrieve services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.clientPolicies) {
      let { type } = await this.props.getAuthClientPoliciesList();
      if (type === GET_AUTH_LIST_CLIENT_POLICIES_FAILURE) {
        this.props.showToast('Failed to retrieve client policies, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.taxPolicies) {
      let { type } = await this.props.getTaxPolicies();
      if (type === GET_TAX_POLICIES_FAILURE) {
        this.props.showToast('Failed to retrieve tax policies, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.brokerAgents) {
      let { type } = await this.props.getBrokerAgents();
      if (type === GET_ALL_BROKER_AGENTS_FAILURE) {
        this.props.showToast('Failed to retrieve broker/agents, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.vendors) {
      let { type } = await this.props.getVendors();
      if (type === GET_VENDORS_FAILURE) {
        this.props.showToast('Failed to retrieve vendors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.vendorContacts) {
      let { type } = await this.props.getVendorContacts();
      if (type === GET_VENDOR_CONTACT_FAILURE) {
        this.props.showToast('Failed to retrieve vendor contacts, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.inspectors) {
      let { type } = await this.props.getInspectors();
      if (type === GET_INSPECTORS_FAILURE) {
        this.props.showToast('Failed to retrieve inspectors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.titleReps) {
      let { type } = await this.props.getTitleReps();
      if (type === GET_TITLE_REP_FAILURE) {
        this.props.showToast('Failed to retrieve title reps, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }
    
    if (!this.props.expenseCodes) {
      let { type } = await this.props.getExpenseCodes();
      if (type === GET_EXPENSE_CODES_FAILURE) {
        this.props.showToast('Failed to retrieve expense codes, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.expenseSummaries) {
      let { type } = await this.props.getExpenseSummaries();
      if (type === GET_EXPENSE_SUMMARIES_FAILURE) {
        this.props.showToast('Failed to retrieve expense summaries, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.expenseLines) {
      let { type } = await this.props.getExpenseLines();
      if (type === GET_EXPENSE_LINES_FAILURE) {
        this.props.showToast('Failed to retrieve expense lines, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.voucherSummaries) {
      let { type } = await this.props.getVoucherSummaries();
      if (type === GET_VOUCHER_SUMMARIES_FAILURE) {
        this.props.showToast('Failed to retrieve voucher summaries, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    if (!this.props.clients) {
      let { type } = await this.props.getClients();
      if (type === GET_CLIENTS_FAILURE) {
        this.props.showToast('Failed to retrieve clients, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }

    this.setState({ isLoading: false});
  }

  render() {
    Log.trace('RENDER', 'ReferentialDataManagementViw');
    return (
      <>
        <Fragment />
        {this.state.isLoading && <FullscreenSpinner/>}
        {!this.state.isLoading && (
          <div className="px-2">
            <AuthorizationSummariesTable />
            <AuthServicesTable />
            <ExpenseSummariesTable />
            <ClientPoliciesTable />
            <TaxPoliciesTable />
            <BrokerAgentsTable />
            <VendorsTable />
            <VendorContactsTable />
            <InspectorsTable />
            <TitleRepTable />
            <ExpenseCodesTable />
            <ExpenseLineTable />
            <VoucherSummariesTable />
            <TestingSandbox />
          </div>
        )}
      </>
    );
  }
}

ReferentialDataManagementView.propTypes = {
  setPageTitle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    authorizationSummaries,
    authorizationSummariesLoading,
    clientPolicies,
    clientPoliciesLoading,
    allServices,
    allServicesLoading,
    brokerAgentsLoading,
    brokerAgents,
    vendors,
    vendorContacts,
    inspectors,
    isInspectorsLoading,
    titleReps,
    isTitleRepIsLoading,
    expenseCodes,
  } = state.authorizations;
  const { expenseSummaries, expenseLines, voucherSummaries } = state.expenses;
  const { clients } = state.clients;

  return {
    authorizationSummaries,
    allServices,
    authorizationSummariesLoading,
    allServicesLoading,
    clientPolicies,
    clientPoliciesLoading,
    brokerAgentsLoading,
    brokerAgents,
    vendors,
    vendorContacts,
    inspectors,
    isInspectorsLoading,
    titleReps,
    isTitleRepIsLoading,
    expenseCodes,
    expenseSummaries,
    expenseLines,
    voucherSummaries,
    clients,
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    getAuthorizationSummaries,
    getAuthServices,
    setPageTitle,
    showToast,
    getAuthClientPoliciesList,
    getTaxPolicies,
    getBrokerAgents,
    getVendors,
    getVendorContacts,
    getInspectors,
    getTitleReps,
    getExpenseCodes,
    getExpenseSummaries,
    getExpenseLines,
    getVoucherSummaries,
    getClients,
  }),
)(ReferentialDataManagementView);
